<script>
import Vue, { watch } from "vue";
import Multiselect from "vue-multiselect";
import Eye from "@/svgs/Eye.vue";
import EyeActive from "@/svgs/EyeActive.vue";
import EyeDisabled from "@/svgs/EyeDisabled.vue";
import Search from "@/svgs/Search.vue";
export default Vue.extend({
  components: { Multiselect, Eye, EyeActive, EyeDisabled, Search },
  props: {
    value: [String, Number, Array, Object],
    localField: Boolean,
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    is_invalid: { type: Boolean, default: false },
    type: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    openDirection: { type: String, default: "bottom" },
    symbol: String,
    trackBy: String,
    itemLabel: String,
  },
  data() {
    return {
      updatedValue: this.value,
      showPassword: false,
    };
  },
  watch: {
    updatedValue(val, old) {
      this.$emit("input", val);
    },
  },
});
</script>
<template>
  <div>
    <div class="relative" v-if="type === 'search'">
      <input
        class="pl-10 bg-white ws-shadow w-full rounded-lg py-2 text-gray-8 focus:border-none outline-none"
        id="search-field"
        type="text"
        :placeholder="placeholder"
        v-model="updatedValue"
      />

      <div class="absolute top-1/2 left-4 -translate-y-1/2">
        <label for="search-field" class="cursor-pointer">
          <search />
        </label>
      </div>
    </div>
    <div class="form-group">
      <input
        :class="is_invalid && 'is-invalid'"
        v-if="type === 'email'"
        :type="type"
        :placeholder="placeholder"
        v-model="updatedValue"
      />

      <multiselect
        v-if="type === 'Select'"
        :class="is_invalid && 'is-invalid'"
        v-model="updatedValue"
        :options="options"
        :searchable="searchable"
        :show-labels="showLabels"
        :open-direction="openDirection"
        :placeholder="placeholder"
        :track-by="trackBy"
        :label="itemLabel"
      ></multiselect>
      <multiselect
        v-if="type === 'Multiselect'"
        :class="is_invalid && 'is-invalid'"
        :multiple="true"
        v-model="updatedValue"
        :options="options"
        :searchable="searchable"
        :show-labels="showLabels"
        :open-direction="openDirection"
        :placeholder="placeholder"
      ></multiselect>
      <div class="relative" v-if="type === 'password'">
        <input
          :class="is_invalid && 'is-invalid'"
          :type="showPassword ? 'text' : 'password'"
          :placeholder="placeholder"
          v-model="updatedValue"
        />

        <div
          v-if="is_invalid"
          class="absolute top-1/2 right-4 -translate-y-1/2 cursor-pointer"
          @click="showPassword = !showPassword"
        >
          <eye-active v-if="showPassword" />
          <eye-disabled v-else />
        </div>
        <div
          v-else
          class="absolute top-1/2 right-4 -translate-y-1/2 cursor-pointer"
          @click="showPassword = !showPassword"
        >
          <eye-active v-if="showPassword" />
          <eye v-else />
        </div>
      </div>

      <input
        v-if="type === 'Number'"
        :class="is_invalid && 'is-invalid'"
        :type="type"
        :placeholder="placeholder"
        v-model="updatedValue"
      />
      <input
        v-if="type === 'Text'"
        :class="is_invalid && 'is-invalid'"
        :type="type"
        :placeholder="placeholder"
        v-model="updatedValue"
      />
      <input
        v-if="type === 'Time'"
        :class="is_invalid && 'is-invalid'"
        :type="type"
        :placeholder="placeholder"
        v-model="updatedValue"
      />

      <div v-if="type === 'Radio'" class="radio-group">
        <label :class="is_invalid && 'text-danger'">{{ label }}?</label>
        <input
          v-model="updatedValue"
          type="radio"
          :id="`${label}0`"
          :value="options[0]"
        />
        <label :for="`${label}0`">{{ options[0] }}</label>

        <input
          v-model="updatedValue"
          :class="is_invalid && 'is-invalid'"
          type="radio"
          :id="`${label}1`"
          :value="options[1]"
        />
        <label :for="`${label}1`">{{ options[1] }}</label>
      </div>
      <template v-if="type === 'Categories'">
        <v-row v-for="(category, index) in options" :key="index" class="px-2">
          <v-col class="pb-0 text-info" cols="12">
            <label>{{ category.name }}</label>
          </v-col>
          <v-col
            class="py-0"
            cols="4"
            v-for="(subCategory, index) in category.children"
            :key="index"
          >
            <v-checkbox
              v-model="updatedValue"
              :label="subCategory.name"
              color="info"
              :value="subCategory.id"
              hide-details
              :ripple="false"
            ></v-checkbox>
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #9aa5b0;
}
[type="radio"]:checked + label {
  color: #101820;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 17px;
  height: 17px;
  border: 2px solid #9aa5b0;
  background: #fff;
  border-radius: 100%;
}
[type="radio"]:checked + label:before {
  border: 2px solid #65cd54 !important;
}
[type="radio"]:not(:checked) + label:before {
  outline: 2px solid #9aa5b0;
  outline-offset: -5px;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 11px;
  height: 11px;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  border: 2px solid #65cd54;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:checked + label:after {
  background: #65cd54;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.form-group {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 10px;
    color: #101820;
    font-size: 15px;
    span {
      color: #48beea;
    }
  }

  input {
    background: #9aa5b030;
    box-shadow: 0px 2px 10px rgba(87, 105, 123, 0.2);
    border-radius: 5px;
    min-height: 35px;
    padding: 10px 15px;
    transition: 0.3s ease all;
    border: 1px solid transparent;
    outline: none;
    width: 100%;

    &::placeholder {
      font-family: lato;
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.radio-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
  label {
    margin-right: 15px;
  }
}
</style>
