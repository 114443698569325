<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1810_6682)">
      <path
        d="M3.9375 0C4.08668 0 4.22976 0.0592632 4.33525 0.164752C4.44074 0.270242 4.5 0.413316 4.5 0.5625V1.125H13.5V0.5625C13.5 0.413316 13.5593 0.270242 13.6648 0.164752C13.7702 0.0592632 13.9133 0 14.0625 0C14.2117 0 14.3548 0.0592632 14.4602 0.164752C14.5657 0.270242 14.625 0.413316 14.625 0.5625V1.125H15.75C16.3467 1.125 16.919 1.36205 17.341 1.78401C17.7629 2.20597 18 2.77826 18 3.375V15.75C18 16.3467 17.7629 16.919 17.341 17.341C16.919 17.7629 16.3467 18 15.75 18H2.25C1.65326 18 1.08097 17.7629 0.65901 17.341C0.237053 16.919 0 16.3467 0 15.75V3.375C0 2.77826 0.237053 2.20597 0.65901 1.78401C1.08097 1.36205 1.65326 1.125 2.25 1.125H3.375V0.5625C3.375 0.413316 3.43426 0.270242 3.53975 0.164752C3.64524 0.0592632 3.78832 0 3.9375 0V0ZM2.25 2.25C1.95163 2.25 1.66548 2.36853 1.4545 2.5795C1.24353 2.79048 1.125 3.07663 1.125 3.375V15.75C1.125 16.0484 1.24353 16.3345 1.4545 16.5455C1.66548 16.7565 1.95163 16.875 2.25 16.875H15.75C16.0484 16.875 16.3345 16.7565 16.5455 16.5455C16.7565 16.3345 16.875 16.0484 16.875 15.75V3.375C16.875 3.07663 16.7565 2.79048 16.5455 2.5795C16.3345 2.36853 16.0484 2.25 15.75 2.25H2.25Z"
        fill="#9AA5B0"
      />
      <path
        d="M2.8125 4.5C2.8125 4.35082 2.87176 4.20774 2.97725 4.10225C3.08274 3.99676 3.22582 3.9375 3.375 3.9375H14.625C14.7742 3.9375 14.9173 3.99676 15.0227 4.10225C15.1282 4.20774 15.1875 4.35082 15.1875 4.5V5.625C15.1875 5.77418 15.1282 5.91726 15.0227 6.02275C14.9173 6.12824 14.7742 6.1875 14.625 6.1875H3.375C3.22582 6.1875 3.08274 6.12824 2.97725 6.02275C2.87176 5.91726 2.8125 5.77418 2.8125 5.625V4.5ZM12.375 8.4375C12.375 8.28832 12.4343 8.14524 12.5398 8.03975C12.6452 7.93426 12.7883 7.875 12.9375 7.875H14.0625C14.2117 7.875 14.3548 7.93426 14.4602 8.03975C14.5657 8.14524 14.625 8.28832 14.625 8.4375V9.5625C14.625 9.71168 14.5657 9.85476 14.4602 9.96025C14.3548 10.0657 14.2117 10.125 14.0625 10.125H12.9375C12.7883 10.125 12.6452 10.0657 12.5398 9.96025C12.4343 9.85476 12.375 9.71168 12.375 9.5625V8.4375ZM9 8.4375C9 8.28832 9.05926 8.14524 9.16475 8.03975C9.27024 7.93426 9.41332 7.875 9.5625 7.875H10.6875C10.8367 7.875 10.9798 7.93426 11.0852 8.03975C11.1907 8.14524 11.25 8.28832 11.25 8.4375V9.5625C11.25 9.71168 11.1907 9.85476 11.0852 9.96025C10.9798 10.0657 10.8367 10.125 10.6875 10.125H9.5625C9.41332 10.125 9.27024 10.0657 9.16475 9.96025C9.05926 9.85476 9 9.71168 9 9.5625V8.4375ZM3.375 11.8125C3.375 11.6633 3.43426 11.5202 3.53975 11.4148C3.64524 11.3093 3.78832 11.25 3.9375 11.25H5.0625C5.21168 11.25 5.35476 11.3093 5.46025 11.4148C5.56574 11.5202 5.625 11.6633 5.625 11.8125V12.9375C5.625 13.0867 5.56574 13.2298 5.46025 13.3352C5.35476 13.4407 5.21168 13.5 5.0625 13.5H3.9375C3.78832 13.5 3.64524 13.4407 3.53975 13.3352C3.43426 13.2298 3.375 13.0867 3.375 12.9375V11.8125ZM6.75 11.8125C6.75 11.6633 6.80926 11.5202 6.91475 11.4148C7.02024 11.3093 7.16332 11.25 7.3125 11.25H8.4375C8.58668 11.25 8.72976 11.3093 8.83525 11.4148C8.94074 11.5202 9 11.6633 9 11.8125V12.9375C9 13.0867 8.94074 13.2298 8.83525 13.3352C8.72976 13.4407 8.58668 13.5 8.4375 13.5H7.3125C7.16332 13.5 7.02024 13.4407 6.91475 13.3352C6.80926 13.2298 6.75 13.0867 6.75 12.9375V11.8125Z"
        fill="#9AA5B0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1810_6682">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
