<script>
import SideBarLogo from "@/svgs/SideBarLogo.vue";
//import { sideMenu } from "./sideMenu";
import { mapGetters, mapActions } from "vuex";
import * as types from "@/state/types";
export default {
  components: { SideBarLogo },
  data() {
    return {
      sideMenu: [
        {
          service: "Warehousing",
          items: [
            {
              name: "Inbound Requests",
              icon: "$vuetify.icons.inbound",
              path: "/",
            },
            {
              name: "Order Requests",
              icon: "$vuetify.icons.orders",
              path: "/warehousing/order-requests",
            },
          ],
        },
        {
          service: "Last Mile",
          items: [
            {
              name: "My Orders",
              icon: "$vuetify.icons.orders",
              path: "/last-mile/my-orders",
            },
            {
              name: "My Returns",
              icon: "$vuetify.icons.returns",
              path: "/last-mile/my-returns",
            },
          ],
        },
        {
          service: "Merchants",
          items: [
            {
              name: "My Merchant list",
              icon: "$vuetify.icons.merchant",
              path: "/merchant/list",
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER,
    }),
  },
  methods: {
    ...mapActions({
      logout: types.LOGOUT_USER,
    }),
    handleLogout() {
      this.logout();
    },
  },
};
</script>
<template>
  <div class="fixed top-0 bottom-0 w-64 h-full p-1">
    <div class="side__bar-bg rounded-md flex flex-column align-center pt-13">
      <SideBarLogo />
      <v-avatar class="mt-6 mb-2">
        <img src="@/assets/images/profile_2.png" />
      </v-avatar>
      <span class="text-primary">{{ user?.email }}</span>
      <div class="mt-6 w-full text-center">
        <div
          class="text-center"
          v-for="({ service, items }, i) in sideMenu"
          :key="i"
        >
          <span class="font-standard text-xs text-gray">{{ service }}</span>

          <router-link
            v-for="({ name, icon, path }, i) in items"
            :key="i"
            :to="path"
            active-class="router--active"
            class="router font-standard"
            :exact="path === '/' && true"
            ><div class="router-icon">
              <v-icon color="primary">{{ icon }}</v-icon>
            </div>

            <div class="router-name">
              <span>{{ name }}</span>
            </div>
          </router-link>
        </div>
      </div>
      <div class="hover:cursor-pointer mt-auto" @click="logout">
        <v-icon size="60" color="primary">$vuetify.icons.logout</v-icon>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.side__bar-bg {
  background-image: linear-gradient(
    180deg,
    rgba(72, 190, 234, 0.6) 0%,
    rgba(255, 227, 202, 0.6) 123.02%
  );
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.router {
  display: flex;
  width: 100%;
  margin-block: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 6px 8px;
  border-radius: 23px;
  width: 90%;
  margin: 10px auto;
  justify-content: center;
  align-items: center;
  color: var(--v-gray-base);
  &-icon {
    align-self: start;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 8px;
    border-radius: 50%;
  }
  &-name {
    flex-grow: 1;
    font-size: 16px;
    font-weight: 400;
    color: var(--v-gray-base);
  }
  &--active {
    background-color: var(--v-secondary-base);
    .router-name {
      color: #fff !important;
    }
  }
}
</style>
<!-- <div class="container--fluid mx-auto px-8 mt-5"> -->
