import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import {
  pinIcon,
  locationIcon,
  targetIcon,
  warehouseIcon,
  imageIcon,
  MailIcon,
  StarIcon,
  LineIcon,
  RadioIcon,
  PlayIcon,
  UploadIcon,
  DownloadIcon,
  ReportIcon,
  uploadIconDark,
  UploadImage,
  UploadVideo,
  checkIcon,
  errorIcon,
  ClockIcon,
  EditIcon,
  redLineIcon,
  InboundIcon,
  MerchantIcon,
  OrdersIcon,
  ReturnsIcon,
  logoutIcon,
  NotificationIcon,
  ChatIcon,
  DoubleArrowIcon,
  MoreIcon,
  RightArrowIcon,
  checkDisabledIcon,
  westoreIcon,
  personIcon,
  CircleIcon,
  FilterIcon,
  CalendarIcon,
  CalendarDisabledIcon,
  PaginationFirstIcon,
  PaginationLastIcon,
  PaginationNextIcon,
  PaginationPrevIcon,
} from "@/assets/icons";
Vue.use(Vuetify);
const opts = {
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#101820",
        "primary-light": "#9FAAB4",
        "primary-lighter": "#DDE1E5",
        secondary: "#e33065",
        info: "#46beea",
        warning: "#f7ac46",
        success: "#2ad03d",
        danger: "#CB3243",
        white: "#ffffff",
        gray: "#57697B",
        "gray-9": "#344658",
        "gray-8": "#9AA5B0",
        "gray-7": "#DDE1E5",
        "gray-6": "#D9D9D9",
        "gray-light": "#F5F6F7",
      },
    },
  },
  icons: {
    values: {
      pin: {
        component: pinIcon,
      },
      location: {
        component: locationIcon,
      },
      target: {
        component: targetIcon,
      },
      warehouse: {
        component: warehouseIcon,
      },
      imageIcon: {
        component: imageIcon,
      },
      mail: {
        component: MailIcon,
      },
      star: {
        component: StarIcon,
      },
      line: {
        component: LineIcon,
      },
      redLine: {
        component: redLineIcon,
      },
      radio: {
        component: RadioIcon,
      },
      play: {
        component: PlayIcon,
      },
      upload: {
        component: UploadIcon,
      },
      download: {
        component: DownloadIcon,
      },
      report: {
        component: ReportIcon,
      },
      uploadDark: {
        component: uploadIconDark,
      },
      uploadVideo: {
        component: UploadVideo,
      },
      uploadImage: {
        component: UploadImage,
      },
      check: {
        component: checkIcon,
      },
      checkDisabled: {
        component: checkDisabledIcon,
      },
      error: {
        component: errorIcon,
      },
      clock: {
        component: ClockIcon,
      },
      edit: {
        component: EditIcon,
      },
      inbound: {
        component: InboundIcon,
      },
      orders: {
        component: OrdersIcon,
      },
      merchant: {
        component: MerchantIcon,
      },
      returns: {
        component: ReturnsIcon,
      },
      logout: {
        component: logoutIcon,
      },
      notification: {
        component: NotificationIcon,
      },
      chat: {
        component: ChatIcon,
      },
      doubleArrow: {
        component: DoubleArrowIcon,
      },
      more: {
        component: MoreIcon,
      },
      rightArrow: {
        component: RightArrowIcon,
      },
      westoreIcon: {
        component: westoreIcon,
      },
      personIcon: {
        component: personIcon,
      },
      circle: {
        component: CircleIcon,
      },
      filter: {
        component: FilterIcon,
      },
      calendar: {
        component: CalendarIcon,
      },
      calendarDisabled: {
        component: CalendarDisabledIcon,
      },
      paginationFirst: {
        component: PaginationFirstIcon,
      },
      paginationLast: {
        component: PaginationLastIcon,
      },
      paginationNext: {
        component: PaginationNextIcon,
      },
      paginationPrev: {
        component: PaginationPrevIcon,
      },
    },
  },
};
export const vuetify = new Vuetify(opts);
