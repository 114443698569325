<template>
  <div>
    <div class="flex">
      <div class="flex">
        <check-box
          class="mr-4"
          :selected="allItemsSelected"
          @click.native="toggleAllItemsSelection"
        /><span class="font-heading font-medium text-sm text-gray-8"
          >Select All
        </span>
      </div>
      <div class="font-heading font-medium text-sm text-gray-8">
        Selected:
        <span class="text-info">( {{ selectedItemsIds.length }} )</span> Orders
      </div>
    </div>

    <div class="pb-10 mt-10">
      <table-row
        v-for="order in orders"
        :key="order.id"
        :order="order"
        :toggleItemSelection="toggleItemSelection"
        :itemIsSelected="itemIsSelected"
      />
    </div>
    <div class="flex justify-end">
      <div class="flex align-start justify-end">
        <div
          class="text-center"
          @click="$emit('paginate', 1)"
          :class="
            meta.current_page === 1 ? ' cursor-not-allowed' : 'cursor-pointer'
          "
        >
          <v-btn :disabled="meta.current_page === 1" icon>
            <v-icon>$vuetify.icons.paginationFirst</v-icon>
          </v-btn>
          <div class="font-standard text-xs text-info">First</div>
        </div>
        <div
          class="bg-info rounded-full text-center cursor-pointer w-6 h-6 mt-2 mx-2"
          :class="meta.current_page === 1 ? 'bg-gray-8 cursor-not-allowed' : ''"
          v-on="
            meta.current_page !== 1
              ? { click: () => $emit('paginate', meta.current_page - 1) }
              : {}
          "
        >
          <v-icon size="16" class="mb-0.5"
            >$vuetify.icons.paginationPrev</v-icon
          >
        </div>
        <div
          class="bg-gray-6 rounded-full text-center cursor-pointer w-6 h-6 mt-2 mx-2"
          v-for="n in meta.last_page"
          :key="n"
          :class="
            meta.current_page === n ? 'bg-primary text-white' : 'text-gray'
          "
          @click="$emit('paginate', n)"
        >
          {{ n }}
        </div>
        <div
          class="bg-info rounded-full text-center cursor-pointer w-6 h-6 mt-2 mx-2"
          :class="
            meta.current_page === meta.last_page
              ? 'bg-gray-8 cursor-not-allowed'
              : ''
          "
          v-on="
            meta.current_page !== meta.last_page
              ? { click: () => $emit('paginate', meta.current_page + 1) }
              : {}
          "
        >
          <v-icon size="16" class="mb-0.5"
            >$vuetify.icons.paginationNext</v-icon
          >
        </div>
        <div
          class="text-center"
          :class="
            meta.current_page === meta.last_page
              ? ' cursor-not-allowed'
              : 'cursor-pointer'
          "
          v-on="
            meta.current_page !== meta.last_page
              ? { click: () => $emit('paginate', meta.last_page) }
              : {}
          "
        >
          <v-btn :disabled="meta.current_page === meta.last_page" icon>
            <v-icon>$vuetify.icons.paginationLast</v-icon>
          </v-btn>
          <div class="font-standard text-xs text-info">Last</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CheckBox from "@/components/CheckBox.vue";
import TableRow from "@/components/TableRow.vue";
export default {
  components: { CheckBox, TableRow },
  props: {
    orders: {
      type: Array,
      required: true,
      default: () => [],
    },
    meta: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedItems: [],
      selectedItemsIds: [],
    };
  },
  computed: {
    allItemsSelected() {
      return this.selectedItemsIds.length === this.orders.length;
    },
  },
  watch: {
    selectedItemsIds(val) {
      this.$emit("itemsSelected", val);
    },
  },
  methods: {
    toggleAllItemsSelection() {
      if (this.allItemsSelected) {
        this.selectedItemsIds = [];
        this.selectedItems = [];
      } else {
        this.selectedItems = this.orders;
        this.selectedItemsIds = this.orders.map(({ id }) => id);
      }
    },
    toggleItemSelection(item) {
      if (this.itemIsSelected(item)) {
        this.selectedItems = this.selectedItems.filter(
          ({ id }) => id !== item.id
        );
        this.selectedItemsIds = this.selectedItemsIds.filter(
          (id) => id !== item.id
        );

        return;
      }
      this.selectedItems.push(item);
      this.selectedItemsIds.push(item.id);
    },
    itemIsSelected({ id }) {
      return this.selectedItemsIds.includes(id);
    },
  },
};
</script>
<style lang="scss"></style>
