import Vue from "vue";
import vueRouter from "vue-router";
import VueMeta from "vue-meta";
import { Route, NavigationGuardNext } from "vue-router";
import { routes } from "./routes";
import * as types from "@/state/types";
import { store } from "@/state/store";

Vue.use(vueRouter);
Vue.use(VueMeta);
const router = new vueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  // Check if auth is required on this route
  // (including nested routes).
  const BearerToken = store.getters["token"];
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  const authenticated = store.getters["authenticated"];

  // If auth isn't required for the route, just continue.
  if (!authRequired) {
    const authPages = [
      "/login",
      "/register",
      "/forgot-password",
      "/reset-password",
    ];
    if (authPages.includes(routeTo.path) && authenticated) {
      return next("/");
    }
    return next();
  }

  // If auth is required and the user is logged in...
  if (authenticated) {
    return next();
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin();

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-inner-declarations
  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: "login", query: { redirectFrom: routeTo.fullPath } });
  }
});
export default router;
