<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="4.1665" r="2.5" fill="#48BEEA" />
    <circle cx="10" cy="10" r="2.5" fill="#48BEEA" />
    <ellipse cx="10" cy="15.833" rx="2.5" ry="2.5" fill="#48BEEA" />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
