import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import Layout from "@/router/layout/layout.vue";
import AuthLayout from "@/router/layout/authLayout.vue";
import FormItem from "@/components/FormItem.vue";
import FormError from "@/components/FormError.vue";
import PageHeader from "@/components/pageHeader.vue";
import BreedCrumbs from "@/components/BreedCrumbs.vue";
import FormSkeleton from "@/components/skeletons/FormSkeleton.vue";
import SkeletonLoader from "@/components/skeletons/SkeletonLoader.vue";
import OrdersTable from "@/components/Order/OrdersTable.vue";
import Base from "@/mixins/base";
import { store } from "@/state/store";
import i18n from "@/i18n";
import ToggleButton from "vue-js-toggle-button";
import Toast from "vue-toastification";
import Vuelidate from "vuelidate";
import VueGtag from "vue-gtag";
import "@/index.css";
import { vuetify } from "@/plugins";
import "@/design/app.scss";
import "boxicons/css/boxicons.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue-toastification/dist/index.css";

const options = {
  position: "top-right",
};
Vue.config.productionTip = false;
Vue.component("layout", Layout);
Vue.component("auth-layout", AuthLayout);
Vue.component("form-item", FormItem);
Vue.component("form-error", FormError);
Vue.component("page-header", PageHeader);
Vue.component("breed-crumbs", BreedCrumbs);
Vue.component("form-skeleton", FormSkeleton);
Vue.component("skeleton-loader", SkeletonLoader);
Vue.component("orders-table", OrdersTable);
Vue.use(ToggleButton);
Vue.mixin(Base);
Vue.use(Toast, options);
Vue.use(Vuelidate);
Vue.use(
  VueGtag,
  {
    config: {
      id: "G-KM76002030",
      params: {
        send_page_view: false,
      },
    },
    appName: "service-provider",
    pageTrackerScreenviewEnabled: true,
  },
  router
);
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
