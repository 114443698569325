<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.59132 2.27875C2.56222 2.30778 2.53913 2.34226 2.52337 2.38023C2.50762 2.41819 2.49951 2.4589 2.49951 2.5C2.49951 2.5411 2.50762 2.58181 2.52337 2.61977C2.53913 2.65774 2.56222 2.69222 2.59132 2.72125L4.8707 5L2.59132 7.27875C2.53264 7.33743 2.49968 7.41702 2.49968 7.5C2.49968 7.58298 2.53264 7.66257 2.59132 7.72125C2.65 7.77993 2.72959 7.81289 2.81257 7.81289C2.89556 7.81289 2.97514 7.77993 3.03382 7.72125L5.53382 5.22125C5.56292 5.19222 5.58601 5.15774 5.60177 5.11977C5.61752 5.08181 5.62563 5.0411 5.62563 5C5.62563 4.9589 5.61752 4.91819 5.60177 4.88023C5.58601 4.84226 5.56292 4.80778 5.53382 4.77875L3.03382 2.27875C3.00479 2.24965 2.97031 2.22656 2.93234 2.2108C2.89438 2.19505 2.85367 2.18694 2.81257 2.18694C2.77147 2.18694 2.73077 2.19505 2.6928 2.2108C2.65483 2.22656 2.62035 2.24965 2.59132 2.27875V2.27875ZM7.18757 0.625C7.27045 0.625 7.34994 0.657924 7.40854 0.716529C7.46715 0.775134 7.50007 0.85462 7.50007 0.9375V9.0625C7.50007 9.14538 7.46715 9.22487 7.40854 9.28347C7.34994 9.34208 7.27045 9.375 7.18757 9.375C7.10469 9.375 7.0252 9.34208 6.9666 9.28347C6.90799 9.22487 6.87507 9.14538 6.87507 9.0625V0.9375C6.87507 0.85462 6.90799 0.775134 6.9666 0.716529C7.0252 0.657924 7.10469 0.625 7.18757 0.625Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
