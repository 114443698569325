<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1779_5314)">
      <path
        d="M9 18C9.59674 18 10.169 17.763 10.591 17.341C11.0129 16.919 11.25 16.3467 11.25 15.75H6.75C6.75 16.3467 6.98705 16.919 7.40901 17.341C7.83097 17.763 8.40326 18 9 18ZM9 2.15776L8.10338 2.33888C7.08633 2.54611 6.17212 3.09837 5.51548 3.90221C4.85884 4.70604 4.50011 5.71207 4.5 6.75001C4.5 7.45651 4.34925 9.22163 3.98362 10.9598C3.80362 11.8226 3.56063 12.7215 3.23775 13.5H14.7622C14.4394 12.7215 14.1975 11.8238 14.0164 10.9598C13.6507 9.22163 13.5 7.45651 13.5 6.75001C13.4996 5.71226 13.1408 4.7065 12.4842 3.9029C11.8275 3.0993 10.9135 2.54719 9.89662 2.34001L9 2.15663V2.15776ZM15.9975 13.5C16.2484 14.0029 16.5386 14.4011 16.875 14.625H1.125C1.46137 14.4011 1.75162 14.0029 2.0025 13.5C3.015 11.475 3.375 7.74001 3.375 6.75001C3.375 4.02751 5.31 1.75501 7.88063 1.23638C7.86492 1.07996 7.88218 0.921975 7.93129 0.772628C7.98039 0.623281 8.06026 0.485884 8.16573 0.369298C8.27119 0.252712 8.39993 0.159527 8.54362 0.0957504C8.68732 0.0319741 8.84279 -0.000976562 9 -0.000976562C9.15721 -0.000976563 9.31268 0.0319741 9.45638 0.0957504C9.60007 0.159527 9.72881 0.252712 9.83428 0.369298C9.93974 0.485884 10.0196 0.623281 10.0687 0.772628C10.1178 0.921975 10.1351 1.07996 10.1194 1.23638C11.3909 1.49502 12.5341 2.18516 13.3551 3.18995C14.1762 4.19473 14.6248 5.4524 14.625 6.75001C14.625 7.74001 14.985 11.475 15.9975 13.5Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1779_5314">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
