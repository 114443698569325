<template>
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 6C6.79565 6 7.55871 5.68393 8.12132 5.12132C8.68393 4.55871 9 3.79565 9 3C9 2.20435 8.68393 1.44129 8.12132 0.87868C7.55871 0.316071 6.79565 0 6 0C5.20435 0 4.44129 0.316071 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3C3 3.79565 3.31607 4.55871 3.87868 5.12132C4.44129 5.68393 5.20435 6 6 6ZM8 3C8 3.53043 7.78929 4.03914 7.41421 4.41421C7.03914 4.78929 6.53043 5 6 5C5.46957 5 4.96086 4.78929 4.58579 4.41421C4.21071 4.03914 4 3.53043 4 3C4 2.46957 4.21071 1.96086 4.58579 1.58579C4.96086 1.21071 5.46957 1 6 1C6.53043 1 7.03914 1.21071 7.41421 1.58579C7.78929 1.96086 8 2.46957 8 3ZM12 11C12 12 11 12 11 12H1C1 12 0 12 0 11C0 10 1 7 6 7C11 7 12 10 12 11ZM11 10.996C10.999 10.75 10.846 10.01 10.168 9.332C9.516 8.68 8.289 8 6 8C3.71 8 2.484 8.68 1.832 9.332C1.154 10.01 1.002 10.75 1 10.996H11Z"
      fill="#57697B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.854 3.14601C15.9006 3.19246 15.9375 3.24763 15.9627 3.30838C15.9879 3.36912 16.0009 3.43425 16.0009 3.50001C16.0009 3.56578 15.9879 3.6309 15.9627 3.69165C15.9375 3.75239 15.9006 3.80757 15.854 3.85401L12.854 6.85401C12.8076 6.90058 12.7524 6.93752 12.6916 6.96273C12.6309 6.98793 12.5658 7.00091 12.5 7.00091C12.4342 7.00091 12.3691 6.98793 12.3084 6.96273C12.2476 6.93752 12.1924 6.90058 12.146 6.85401L10.646 5.35401C10.5521 5.26013 10.4994 5.13279 10.4994 5.00001C10.4994 4.86724 10.5521 4.7399 10.646 4.64601C10.7399 4.55213 10.8672 4.49938 11 4.49938C11.1328 4.49938 11.2601 4.55213 11.354 4.64601L12.5 5.79301L15.146 3.14601C15.1924 3.09945 15.2476 3.06251 15.3084 3.0373C15.3691 3.01209 15.4342 2.99912 15.5 2.99912C15.5658 2.99912 15.6309 3.01209 15.6916 3.0373C15.7524 3.06251 15.8076 3.09945 15.854 3.14601Z"
      fill="#57697B"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
