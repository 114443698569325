<template>
  <p class="text-danger font-heading font-medium text-sm text-left mt-1">
    {{ error }}
  </p>
</template>
<script>
export default {
  props: ["error"],
};
</script>
<style></style>
