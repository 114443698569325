var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.params.id)?_c('div',{staticClass:"font-heading font-semibold text-lg text-primary h-full"},_vm._l((_vm.statuses),function({ text, value },i){return _c('div',{key:i,staticClass:"flex align-center h-1/3"},[(_vm.isUpdating && _vm.selectedStatus === value)?_c('v-progress-circular',{attrs:{"size":27,"color":"primary","indeterminate":"","width":2}}):_c('v-icon',_vm._g({class:{
        'cursor-pointer': _vm.checkStatusIsUpdatable(value),
      }},
        _vm.checkStatusIsUpdatable(value)
          ? { click: () => _vm.updateOrderStatus(value) }
          : {}
      ),[_vm._v(" "+_vm._s(_vm.getIcon(value)))]),_c('div',{staticClass:"border-t-2 border-gray-8",class:{
        'border-success': _vm.status >= value,
        'w-1/6': value === 1,
        'w-1/4': value === 2,
        'w-2/5': value === 3,
      }}),_c('div',{staticClass:"py-2 px-6 mb-2 font-standard font-semibold text-sm rounded-xl",class:_vm.getStatusStyle(value)},[_vm._v(" "+_vm._s(text)+" ")])],1)}),0):_c('div',{staticClass:"flex"},[_vm._l((_vm.statuses),function({ text, value },i){return [_c('div',{key:i,staticClass:"text-center"},[_c('div',{staticClass:"py-2 px-6 mb-2 font-standard font-semibold text-sm rounded-xl",class:_vm.getStatusStyle(value)},[_vm._v(" "+_vm._s(text)+" ")]),(_vm.isUpdating && _vm.selectedStatus === value)?_c('v-progress-circular',{attrs:{"size":27,"color":"primary","indeterminate":"","width":2}}):_c('v-icon',_vm._g({class:{
          'cursor-pointer': _vm.checkStatusIsUpdatable(value),
        }},
          _vm.checkStatusIsUpdatable(value)
            ? { click: () => _vm.updateOrderStatus(value) }
            : {}
        ),[_vm._v(" "+_vm._s(_vm.getIcon(value)))])],1),(!(i === _vm.statuses.length - 1))?_c('div',{key:text,staticClass:"border-t-2 border-gray-8 grow mt-4",class:{
        'border-success': _vm.status > value,
      }}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }