<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.6325 13.3875C8.59013 13.3695 8.54988 13.3469 8.5125 13.32L3.2775 8.82C3.07386 8.64583 2.9786 8.37625 3.02762 8.11281C3.07663 7.84937 3.26246 7.63209 3.51512 7.54281C3.76777 7.45354 4.04886 7.50583 4.2525 7.68L8.25 11.115V2.25C8.25 1.83579 8.58579 1.5 9 1.5C9.41421 1.5 9.75 1.83579 9.75 2.25V11.115L13.7625 7.68C14.0773 7.41076 14.5508 7.4477 14.82 7.7625C15.0892 8.0773 15.0523 8.55076 14.7375 8.82L9.4875 13.32C9.45012 13.3469 9.40987 13.3695 9.3675 13.3875L9.2775 13.4475C9.09931 13.5185 8.90069 13.5185 8.7225 13.4475L8.6325 13.3875ZM16.5 15V13.5C16.5 13.0858 16.1642 12.75 15.75 12.75C15.3358 12.75 15 13.0858 15 13.5V14.9475C14.9333 14.9837 14.8583 15.0018 14.7825 15H3.2175C3.14166 15.0018 3.06668 14.9837 3 14.9475V13.5C3 13.0858 2.66421 12.75 2.25 12.75C1.83579 12.75 1.5 13.0858 1.5 13.5V15C1.56799 15.8845 2.33186 16.5517 3.2175 16.5H14.7825C15.6681 16.5517 16.432 15.8845 16.5 15Z"
      fill="#9AA5B0"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
