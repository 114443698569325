<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.19006 1.44006C3.2307 1.39931 3.27897 1.36699 3.33213 1.34493C3.38528 1.32288 3.44226 1.31152 3.49981 1.31152C3.55735 1.31152 3.61433 1.32288 3.66749 1.34493C3.72064 1.36699 3.76892 1.39931 3.80956 1.44006L9.05956 6.69006C9.1003 6.7307 9.13262 6.77897 9.15468 6.83213C9.17673 6.88528 9.18809 6.94226 9.18809 6.99981C9.18809 7.05735 9.17673 7.11433 9.15468 7.16748C9.13262 7.22064 9.1003 7.26892 9.05956 7.30956L3.80956 12.5596C3.7274 12.6417 3.61598 12.6879 3.49981 12.6879C3.38363 12.6879 3.27221 12.6417 3.19006 12.5596C3.1079 12.4774 3.06175 12.366 3.06175 12.2498C3.06175 12.1336 3.1079 12.0222 3.19006 11.9401L8.13118 6.99981L3.19006 2.05956C3.14931 2.01892 3.11699 1.97064 3.09493 1.91749C3.07288 1.86433 3.06152 1.80735 3.06152 1.74981C3.06152 1.69226 3.07288 1.63528 3.09493 1.58213C3.11699 1.52897 3.14931 1.4807 3.19006 1.44006V1.44006Z"
      fill="#48BEEA"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.69006 1.44006C6.7307 1.39931 6.77897 1.36699 6.83213 1.34493C6.88528 1.32288 6.94226 1.31152 6.99981 1.31152C7.05735 1.31152 7.11433 1.32288 7.16748 1.34493C7.22064 1.36699 7.26892 1.39931 7.30956 1.44006L12.5596 6.69006C12.6003 6.7307 12.6326 6.77897 12.6547 6.83213C12.6767 6.88528 12.6881 6.94226 12.6881 6.99981C12.6881 7.05735 12.6767 7.11433 12.6547 7.16748C12.6326 7.22064 12.6003 7.26892 12.5596 7.30956L7.30956 12.5596C7.2274 12.6417 7.11598 12.6879 6.99981 12.6879C6.88363 12.6879 6.77221 12.6417 6.69006 12.5596C6.6079 12.4774 6.56175 12.366 6.56175 12.2498C6.56175 12.1336 6.6079 12.0222 6.69006 11.9401L11.6312 6.99981L6.69006 2.05956C6.64931 2.01892 6.61699 1.97064 6.59493 1.91749C6.57288 1.86433 6.56152 1.80735 6.56152 1.74981C6.56152 1.69226 6.57288 1.63528 6.59493 1.58213C6.61699 1.52897 6.64931 1.4807 6.69006 1.44006V1.44006Z"
      fill="#48BEEA"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
