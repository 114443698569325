<script>
import WestoreLogo from "@/svgs/WestoreLogo.vue";
export default {
  components: { WestoreLogo },
};
</script>
<template>
  <div class="w-full h-full gradient-bg flex flex-col align-center pl-5 pr-4">
    <div class="mt-15">
      <WestoreLogo />
    </div>

    <slot />
  </div>
</template>
<style>
.gradient-bg {
  background-image: url("../../assets/images/bg-auth.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
