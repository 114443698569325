<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.77071 7.3848L5.10404 12.6348C4.89127 12.8761 4.52327 12.8996 4.28154 12.6873C4.04022 12.4745 4.01673 12.1065 4.22904 11.8648L8.55154 6.9998L4.22904 2.13479C4.03316 1.89159 4.06354 1.53727 4.29797 1.33097C4.53241 1.12467 4.88772 1.13958 5.10404 1.36479L9.77071 6.6148C9.96417 6.83501 9.96417 7.16458 9.77071 7.3848Z"
      fill="#9AA5B0"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
