<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.40868 7.72125C7.43778 7.69222 7.46087 7.65774 7.47663 7.61977C7.49238 7.58181 7.50049 7.5411 7.50049 7.5C7.50049 7.4589 7.49238 7.41819 7.47663 7.38023C7.46087 7.34226 7.43778 7.30778 7.40868 7.27875L5.1293 5L7.40868 2.72125C7.46736 2.66257 7.50032 2.58298 7.50032 2.5C7.50032 2.41702 7.46736 2.33743 7.40868 2.27875C7.35 2.22007 7.27041 2.18711 7.18743 2.18711C7.10444 2.18711 7.02486 2.22007 6.96618 2.27875L4.46618 4.77875C4.43708 4.80778 4.41399 4.84226 4.39823 4.88023C4.38248 4.91819 4.37437 4.9589 4.37437 5C4.37437 5.0411 4.38248 5.08181 4.39823 5.11977C4.41399 5.15774 4.43708 5.19222 4.46618 5.22125L6.96618 7.72125C6.99521 7.75035 7.02969 7.77344 7.06766 7.7892C7.10562 7.80495 7.14633 7.81306 7.18743 7.81306C7.22853 7.81306 7.26923 7.80495 7.3072 7.7892C7.34517 7.77344 7.37965 7.75035 7.40868 7.72125V7.72125ZM2.81243 9.375C2.72955 9.375 2.65006 9.34208 2.59146 9.28347C2.53285 9.22487 2.49993 9.14538 2.49993 9.0625V0.9375C2.49993 0.85462 2.53285 0.775134 2.59146 0.716529C2.65006 0.657924 2.72955 0.625 2.81243 0.625C2.89531 0.625 2.9748 0.657924 3.0334 0.716529C3.09201 0.775134 3.12493 0.85462 3.12493 0.9375V9.0625C3.12493 9.14538 3.09201 9.22487 3.0334 9.28347C2.9748 9.34208 2.89531 9.375 2.81243 9.375Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
