import moment from "moment";
import { adminApi } from "@/api";
export default {
  methods: {
    formatTime(time: number, format = " DD - MM - YY -- hh:mma"): string {
      return moment(time * 1000).format(format);
    },
    getPreviousDay(date = new Date(), format = "YYYY-MM-DD") {
      const previous = new Date(date.getTime());
      previous.setDate(date.getDate() - 1);
      return moment(previous).format(format);
    },
    export(endPoint: string, extension = "xlsx", filters: any = {}) {
      return new Promise<void>((resolve, reject) => {
        adminApi
          .get(`${endPoint}/export`, {
            params: {
              ...filters,
            },
            responseType: "blob",
          })
          .then(({ data, headers }) => {
            const type = headers["content-typess"];
            const filename = `order_${new Date().getTime()}.${extension}`;
            this.download(data, type, filename);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    download(data: Blob, type: string, filename: string) {
      const blob = new Blob([data], { type });

      // if (typeof window.navigator.msSaveBlob !== "undefined") {
      //   // IE doesn't allow using a blob object directly as link href.
      //   // Workaround for "HTML7007: One or more blob URLs were
      //   // revoked by closing the blob for which they were created.
      //   // These URLs will no longer resolve as the data backing
      //   // the URL has been freed."
      //   window.navigator.msSaveBlob(blob, filename);
      //   return;
      // }
      const blobURL = window.URL.createObjectURL(blob);
      const l = document.createElement("a");
      l.href = blobURL;
      l.setAttribute("download", filename);
      if (typeof l.download === "undefined") {
        l.setAttribute("target", "_blank");
      }
      document.body.appendChild(l);
      l.click();
      document.body.removeChild(l);
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobURL);
      }, 100);
    },
  },
};
