<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
  >
    <g filter="url(#filter0_d_1821_7800)">
      <circle cx="25" cy="23" r="15" fill="white" />
    </g>
    <path
      d="M28.9945 24.8579L24.8955 20.7158L20.7965 24.8579L24.8955 29L28.9945 24.8579Z"
      fill="#101820"
    />
    <path d="M34.792 24.8578V19H28.9951V24.8578H34.792Z" fill="#101820" />
    <path d="M20.7969 24.8578V19H15V24.8578H20.7969Z" fill="#101820" />
    <defs>
      <filter
        id="filter0_d_1821_7800"
        x="0"
        y="0"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.341176 0 0 0 0 0.411765 0 0 0 0 0.482353 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1821_7800"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1821_7800"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
