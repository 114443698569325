<template lang="">
  <div
    v-if="$route.params.id"
    class="font-heading font-semibold text-lg text-primary h-full"
  >
    <div
      v-for="({ text, value }, i) in statuses"
      class="flex align-center h-1/3"
      :key="i"
    >
      <v-progress-circular
        v-if="isUpdating && selectedStatus === value"
        :size="27"
        color="primary"
        indeterminate
        :width="2"
      ></v-progress-circular>
      <v-icon
        v-on="
          checkStatusIsUpdatable(value)
            ? { click: () => updateOrderStatus(value) }
            : {}
        "
        v-else
        :class="{
          'cursor-pointer': checkStatusIsUpdatable(value),
        }"
      >
        {{ getIcon(value) }}</v-icon
      >
      <div
        class="border-t-2 border-gray-8"
        :class="{
          'border-success': status >= value,
          'w-1/6': value === 1,
          'w-1/4': value === 2,
          'w-2/5': value === 3,
        }"
      ></div>
      <div
        class="py-2 px-6 mb-2 font-standard font-semibold text-sm rounded-xl"
        :class="getStatusStyle(value)"
      >
        {{ text }}
      </div>
    </div>
  </div>
  <div class="flex" v-else>
    <template v-for="({ text, value }, i) in statuses">
      <div class="text-center" :key="i">
        <div
          class="py-2 px-6 mb-2 font-standard font-semibold text-sm rounded-xl"
          :class="getStatusStyle(value)"
        >
          {{ text }}
        </div>
        <v-progress-circular
          v-if="isUpdating && selectedStatus === value"
          :size="27"
          color="primary"
          indeterminate
          :width="2"
        ></v-progress-circular>
        <v-icon
          v-on="
            checkStatusIsUpdatable(value)
              ? { click: () => updateOrderStatus(value) }
              : {}
          "
          v-else
          :class="{
            'cursor-pointer': checkStatusIsUpdatable(value),
          }"
        >
          {{ getIcon(value) }}</v-icon
        >
      </div>
      <div
        v-if="!(i === statuses.length - 1)"
        class="border-t-2 border-gray-8 grow mt-4"
        :class="{
          'border-success': status > value,
        }"
        :key="text"
      ></div>
    </template>
  </div>
</template>
<script>
import { adminApi } from "@/api";
import { eventBus } from "@/mixins";
export default {
  props: {
    status: {
      type: Number,
      required: true,
      default: 1,
    },
    orderId: {
      type: Number,
    },
  },
  data() {
    return {
      statuses: [
        { text: "Processing", value: 1 },
        { text: "Picked & Packed", value: 2 },
        { text: "Dispatched from warehouse", value: 3 },
      ],
      isUpdating: false,
      selectedStatus: null,
    };
  },

  methods: {
    getIcon(value) {
      if (this.status === value) {
        return "$vuetify.icons.check";
      } else if (this.status > value) {
        return "$vuetify.icons.checkDisabled";
      } else {
        return "$vuetify.icons.circle";
      }
    },
    getStatusStyle(value) {
      if (value === 1 && this.status >= 1) {
        return `bg-brown text-white `;
      } else if (value === 2 && this.status >= 2) {
        return `bg-green-100 text-primary `;
      } else if (value === 3 && this.status >= 3) {
        return `bg-purple text-white `;
      } else {
        return "text-gray border border-gray";
      }
    },
    checkStatusIsUpdatable(value) {
      return this.status + 1 === value;
    },
    async updateOrderStatus(value) {
      if (!this.checkStatusIsUpdatable(value)) return;
      this.isUpdating = true;
      this.selectedStatus = value;
      const payload = [{ order_id: this.orderId, status: value }];

      try {
        const { data } = await adminApi.put("/spm/orders/status", {
          ...payload,
        });
        this.$toast.success("Your order status has been updated successfully");
        eventBus.$emit("statusUpdated");
      } catch (error) {
        this.$toast.error("Something went wrong,please try again");
      } finally {
        this.isUpdating = false;
        this.selectedStatus = null;
      }
    },
  },
};
</script>
<style lang=""></style>
