<template lang="">
  <svg
    width="110"
    height="24"
    viewBox="0 0 110 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6.51709H2.14506L5.63973 20.4466L9.6878 6.51709H11.182L15.2309 20.4954L18.7742 6.51709H20.726L16.2433 23.7788H14.3151L10.4109 10.1413L6.38647 23.7788H4.48347L0 6.51709Z"
      fill="#101820"
    />
    <path
      d="M20.7891 17.5989V17.4052C20.7891 13.5926 23.2249 10.936 26.6937 10.936C29.6099 10.936 32.357 12.6974 32.357 17.213V17.8642H22.8618C22.9585 20.7373 24.3317 22.3302 26.9106 22.3302C28.8624 22.3302 29.9958 21.6066 30.2599 20.1334H32.2611C31.8265 22.668 29.7301 23.9963 26.8863 23.9963C23.3193 23.9963 20.7891 21.4854 20.7891 17.5989ZM30.3086 16.2469C30.1161 13.6391 28.7657 12.6014 26.6937 12.6014C24.6453 12.6014 23.2683 14.0021 22.9341 16.2469H30.3086Z"
      fill="#101820"
    />
    <path
      d="M33.5173 18.5162H35.5414C35.8063 20.5681 36.6253 22.3066 40.0964 22.3066C42.313 22.3066 44.0242 21.0027 44.0242 19.0469C44.0242 17.0911 43.1328 16.2714 39.8551 15.7399C36.2401 15.1848 34.0951 14.0502 34.0951 10.9841C34.0951 8.3283 36.3848 6.30078 39.6381 6.30078C43.0841 6.30078 45.2292 7.98974 45.5671 11.0558H43.687C43.2774 8.8834 42.0237 7.98974 39.6381 7.98974C37.2784 7.98974 36.0468 9.17316 36.0468 10.7912C36.0468 12.4809 36.6733 13.3494 40.1923 13.8809C44.0242 14.4879 46.0528 15.6438 46.0528 18.902C46.0528 21.7996 43.5225 23.9964 40.1002 23.9964C35.4926 23.9964 33.8302 21.461 33.5173 18.5162Z"
      fill="#101820"
    />
    <path
      d="M48.8013 20.6162V12.8386H46.9934V11.1526H48.8013V8.28027H50.8009V11.1526H53.7407V12.8424H50.8009V20.4232C50.8009 21.6547 51.3551 22.2578 52.3675 22.2578C52.8938 22.2691 53.4173 22.179 53.9097 21.9925V23.6822C53.5009 23.8271 53.0183 23.9483 52.1985 23.9483C49.9096 23.9483 48.8013 22.5964 48.8013 20.6162Z"
      fill="#101820"
    />
    <path
      d="M54.3232 17.5745V17.3854C54.3232 13.595 56.9501 10.9399 60.5171 10.9399C64.0841 10.9399 66.711 13.5706 66.711 17.361V17.554C66.711 21.3665 64.0834 24.0002 60.4928 24.0002C56.9022 24.0002 54.3232 21.2681 54.3232 17.5745ZM64.6375 17.5745V17.4053C64.6375 14.4849 63.023 12.6015 60.5171 12.6015C57.9861 12.6015 56.396 14.4849 56.396 17.3816V17.5745C56.396 20.4469 57.9625 22.3303 60.5171 22.3303C63.0474 22.3303 64.6375 20.4233 64.6375 17.5745Z"
      fill="#101820"
    />
    <path
      d="M68.6426 11.1526H70.643V13.4204C71.4385 11.996 72.5224 11.007 74.7877 10.9346V12.8172C72.2575 12.9377 70.643 13.7322 70.643 16.8486V23.7775H68.6426V11.1526Z"
      fill="#101820"
    />
    <path
      d="M75.1672 17.5989V17.4052C75.1672 13.5926 77.6031 10.936 81.0719 10.936C83.988 10.936 86.7352 12.6974 86.7352 17.213V17.8642H77.24C77.3382 20.7404 78.7083 22.3302 81.2888 22.3302C83.2405 22.3302 84.374 21.6066 84.6381 20.1334H86.6393C86.2046 22.668 84.1083 23.9963 81.2645 23.9963C77.6975 23.9963 75.1672 21.4854 75.1672 17.5989ZM84.6868 16.2469C84.4935 13.6391 83.1439 12.6014 81.0719 12.6014C79.0235 12.6014 77.6465 14.0021 77.3123 16.2469H84.6868Z"
      fill="#101820"
    />
    <path
      d="M102.428 6.51336L97.8301 1.90771L93.2323 6.51336L97.8301 11.119L102.428 6.51336Z"
      fill="#101820"
    />
    <path d="M108.93 6.51337V0L102.428 0V6.51337H108.93Z" fill="#101820" />
    <path d="M93.2324 6.51337V0L86.7302 0V6.51337H93.2324Z" fill="#101820" />
  </svg>
</template>
<script>
export default {};
</script>
<style lang=""></style>
