<script>
export default {};
</script>
<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2163_12860)">
      <path
        d="M16.6987 14.0475C18.825 12.15 20 10 20 10C20 10 16.25 3.125 9.99995 3.125C8.79944 3.12913 7.61252 3.37928 6.51245 3.86L7.47495 4.82375C8.28425 4.52894 9.13864 4.3771 9.99995 4.375C12.65 4.375 14.8487 5.835 16.46 7.44625C17.2354 8.22586 17.9306 9.08141 18.535 10C18.4625 10.1088 18.3825 10.2288 18.2912 10.36C17.8725 10.96 17.2537 11.76 16.46 12.5538C16.2537 12.76 16.0387 12.9638 15.8137 13.1613L16.6987 14.0475Z"
        fill="#9AA5B0"
      />
      <path
        d="M14.1212 11.47C14.4001 10.6898 14.4518 9.84639 14.2702 9.03798C14.0885 8.22957 13.6811 7.48936 13.0952 6.90348C12.5093 6.3176 11.7691 5.91013 10.9607 5.72849C10.1523 5.54685 9.3089 5.59851 8.5287 5.87745L9.55745 6.9062C10.0378 6.83745 10.5276 6.88151 10.988 7.03491C11.4484 7.18831 11.8668 7.44682 12.2099 7.78997C12.5531 8.13312 12.8116 8.55146 12.965 9.01187C13.1184 9.47227 13.1625 9.96207 13.0937 10.4425L14.1212 11.47ZM10.4425 13.0937L11.47 14.1212C10.6898 14.4001 9.84639 14.4518 9.03798 14.2702C8.22957 14.0885 7.48936 13.6811 6.90348 13.0952C6.3176 12.5093 5.91013 11.7691 5.72849 10.9607C5.54685 10.1523 5.59851 9.3089 5.87745 8.5287L6.9062 9.55745C6.83745 10.0378 6.88151 10.5276 7.03491 10.988C7.18831 11.4484 7.44682 11.8668 7.78997 12.2099C8.13312 12.5531 8.55146 12.8116 9.01187 12.965C9.47227 13.1184 9.96207 13.1625 10.4425 13.0937Z"
        fill="#9AA5B0"
      />
      <path
        d="M4.1875 6.83762C3.9625 7.03762 3.74625 7.24012 3.54 7.44637C2.76456 8.22598 2.0694 9.08153 1.465 10.0001L1.70875 10.3601C2.1275 10.9601 2.74625 11.7601 3.54 12.5539C5.15125 14.1651 7.35125 15.6251 10 15.6251C10.895 15.6251 11.7375 15.4589 12.525 15.1751L13.4875 16.1401C12.3874 16.6208 11.2005 16.8709 10 16.8751C3.75 16.8751 0 10.0001 0 10.0001C0 10.0001 1.17375 7.84887 3.30125 5.95262L4.18625 6.83887L4.1875 6.83762ZM17.0575 17.9426L2.0575 2.94262L2.9425 2.05762L17.9425 17.0576L17.0575 17.9426Z"
        fill="#9AA5B0"
      />
    </g>
    <defs>
      <clipPath id="clip0_2163_12860">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
