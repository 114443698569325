<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8099 12.5599C10.7693 12.6007 10.721 12.633 10.6679 12.6551C10.6147 12.6771 10.5577 12.6885 10.5002 12.6885C10.4426 12.6885 10.3857 12.6771 10.3325 12.6551C10.2794 12.633 10.2311 12.6007 10.1904 12.5599L4.94044 7.30994C4.8997 7.2693 4.86738 7.22103 4.84532 7.16787C4.82327 7.11472 4.81191 7.05774 4.81191 7.00019C4.81191 6.94265 4.82327 6.88567 4.84532 6.83252C4.86738 6.77936 4.8997 6.73108 4.94044 6.69044L10.1904 1.44044C10.2726 1.35829 10.384 1.31214 10.5002 1.31214C10.6164 1.31214 10.7278 1.35829 10.8099 1.44044C10.8921 1.5226 10.9382 1.63402 10.9382 1.75019C10.9382 1.86637 10.8921 1.97779 10.8099 2.05994L5.86882 7.00019L10.8099 11.9404C10.8507 11.9811 10.883 12.0294 10.9051 12.0825C10.9271 12.1357 10.9385 12.1926 10.9385 12.2502C10.9385 12.3077 10.9271 12.3647 10.9051 12.4179C10.883 12.471 10.8507 12.5193 10.8099 12.5599V12.5599Z"
      fill="#48BEEA"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.30994 12.5599C7.2693 12.6007 7.22103 12.633 7.16787 12.6551C7.11472 12.6771 7.05774 12.6885 7.00019 12.6885C6.94265 12.6885 6.88567 12.6771 6.83252 12.6551C6.77936 12.633 6.73108 12.6007 6.69044 12.5599L1.44044 7.30994C1.3997 7.2693 1.36738 7.22103 1.34532 7.16787C1.32326 7.11472 1.31191 7.05774 1.31191 7.00019C1.31191 6.94265 1.32326 6.88567 1.34532 6.83252C1.36738 6.77936 1.3997 6.73108 1.44044 6.69044L6.69044 1.44044C6.7726 1.35829 6.88402 1.31214 7.00019 1.31214C7.11637 1.31214 7.22779 1.35829 7.30994 1.44044C7.3921 1.5226 7.43825 1.63402 7.43825 1.75019C7.43825 1.86637 7.3921 1.97779 7.30994 2.05994L2.36882 7.00019L7.30994 11.9404C7.35069 11.9811 7.38301 12.0294 7.40507 12.0825C7.42712 12.1357 7.43848 12.1926 7.43848 12.2502C7.43848 12.3077 7.42712 12.3647 7.40507 12.4179C7.38301 12.471 7.35069 12.5193 7.30994 12.5599V12.5599Z"
      fill="#48BEEA"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
