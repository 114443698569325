<template>
  <v-skeleton-loader v-bind="attrs" :type="type"></v-skeleton-loader>
</template>

<script>
export default {
  props: {
    type: {
      default: "image",
    },
  },
  data: () => ({
    attrs: {
      class: "mb-6",
      boilerplate: false,
      elevation: 2,
    },
  }),
};
</script>

<style></style>
