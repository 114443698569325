<script>
export default {};
</script>
<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8733 9.9335L14.4733 13.5268C14.5995 13.652 14.6705 13.8224 14.6705 14.0002C14.6705 14.1779 14.5995 14.3483 14.4733 14.4735C14.3481 14.5997 14.1777 14.6707 13.9999 14.6707C13.8222 14.6707 13.6518 14.5997 13.5266 14.4735L9.93325 10.8735C9.00155 11.6048 7.85102 12.0016 6.66659 12.0002C3.72107 12.0002 1.33325 9.61235 1.33325 6.66683C1.33325 3.72131 3.72107 1.3335 6.66659 1.3335C9.6121 1.3335 11.9999 3.72131 11.9999 6.66683C12.0014 7.85127 11.6046 9.0018 10.8733 9.9335ZM6.66659 2.66683C4.45745 2.66683 2.66659 4.45769 2.66659 6.66683C2.66659 8.87597 4.45745 10.6668 6.66659 10.6668C8.87572 10.6668 10.6666 8.87597 10.6666 6.66683C10.6666 4.45769 8.87572 2.66683 6.66659 2.66683Z"
      fill="#9AA5B0"
    />
  </svg>
</template>
