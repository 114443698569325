import axios from "axios";
import { store } from "@/state/store";

const BearerToken = store.getters["token"];

export const adminApi = axios.create({
  baseURL: process.env.VUE_APP_WESTORE_ADMIN_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    authorization: `${BearerToken}`,
  },
});
