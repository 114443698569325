<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1779_5313)">
      <path
        d="M2.25 1.1875C1.95163 1.1875 1.66548 1.31261 1.4545 1.53531C1.24353 1.75801 1.125 2.06006 1.125 2.375V11.875C1.125 12.1899 1.24353 12.492 1.4545 12.7147C1.66548 12.9374 1.95163 13.0625 2.25 13.0625H13.0343C13.6309 13.0626 14.2031 13.3129 14.625 13.7584L16.875 16.1334V2.375C16.875 2.06006 16.7565 1.75801 16.5455 1.53531C16.3345 1.31261 16.0484 1.1875 15.75 1.1875H2.25ZM15.75 0C16.3467 0 16.919 0.250223 17.341 0.695621C17.7629 1.14102 18 1.74511 18 2.375V17.5667C18 17.6842 17.9669 17.799 17.9051 17.8967C17.8432 17.9943 17.7552 18.0704 17.6524 18.1153C17.5495 18.1602 17.4364 18.1718 17.3272 18.1488C17.2181 18.1258 17.1179 18.0691 17.0392 17.9859L13.8296 14.5979C13.6187 14.3752 13.3326 14.2501 13.0343 14.25H2.25C1.65326 14.25 1.08097 13.9998 0.65901 13.5544C0.237053 13.109 0 12.5049 0 11.875V2.375C0 1.74511 0.237053 1.14102 0.65901 0.695621C1.08097 0.250223 1.65326 0 2.25 0L15.75 0Z"
        fill="white"
      />
      <path
        d="M3.375 4.15625C3.375 3.99878 3.43426 3.84776 3.53975 3.73641C3.64524 3.62506 3.78832 3.5625 3.9375 3.5625H14.0625C14.2117 3.5625 14.3548 3.62506 14.4602 3.73641C14.5657 3.84776 14.625 3.99878 14.625 4.15625C14.625 4.31372 14.5657 4.46474 14.4602 4.57609C14.3548 4.68744 14.2117 4.75 14.0625 4.75H3.9375C3.78832 4.75 3.64524 4.68744 3.53975 4.57609C3.43426 4.46474 3.375 4.31372 3.375 4.15625ZM3.375 7.125C3.375 6.96753 3.43426 6.81651 3.53975 6.70516C3.64524 6.59381 3.78832 6.53125 3.9375 6.53125H14.0625C14.2117 6.53125 14.3548 6.59381 14.4602 6.70516C14.5657 6.81651 14.625 6.96753 14.625 7.125C14.625 7.28247 14.5657 7.43349 14.4602 7.54484C14.3548 7.65619 14.2117 7.71875 14.0625 7.71875H3.9375C3.78832 7.71875 3.64524 7.65619 3.53975 7.54484C3.43426 7.43349 3.375 7.28247 3.375 7.125ZM3.375 10.0938C3.375 9.93628 3.43426 9.78525 3.53975 9.6739C3.64524 9.56256 3.78832 9.5 3.9375 9.5H9.5625C9.71168 9.5 9.85476 9.56256 9.96025 9.6739C10.0657 9.78525 10.125 9.93628 10.125 10.0938C10.125 10.2512 10.0657 10.4022 9.96025 10.5136C9.85476 10.6249 9.71168 10.6875 9.5625 10.6875H3.9375C3.78832 10.6875 3.64524 10.6249 3.53975 10.5136C3.43426 10.4022 3.375 10.2512 3.375 10.0938Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1779_5313">
        <rect width="18" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
