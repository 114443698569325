<script>
export default {};
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.55764 2.05764C4.6157 1.99944 4.68467 1.95326 4.7606 1.92175C4.83653 1.89024 4.91793 1.87402 5.00014 1.87402C5.08235 1.87402 5.16375 1.89024 5.23968 1.92175C5.31561 1.95326 5.38458 1.99944 5.44264 2.05764L12.9426 9.55764C13.0008 9.6157 13.047 9.68467 13.0785 9.7606C13.11 9.83653 13.1263 9.91793 13.1263 10.0001C13.1263 10.0823 13.11 10.1638 13.0785 10.2397C13.047 10.3156 13.0008 10.3846 12.9426 10.4426L5.44264 17.9426C5.32528 18.06 5.16611 18.1259 5.00014 18.1259C4.83417 18.1259 4.675 18.06 4.55764 17.9426C4.44028 17.8253 4.37435 17.6661 4.37435 17.5001C4.37435 17.3342 4.44028 17.175 4.55764 17.0576L11.6164 10.0001L4.55764 2.94264C4.49944 2.88458 4.45326 2.81561 4.42175 2.73968C4.39024 2.66375 4.37402 2.58235 4.37402 2.50014C4.37402 2.41793 4.39024 2.33653 4.42175 2.2606C4.45326 2.18467 4.49944 2.1157 4.55764 2.05764Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.55764 2.05764C9.6157 1.99944 9.68467 1.95326 9.7606 1.92175C9.83653 1.89024 9.91793 1.87402 10.0001 1.87402C10.0823 1.87402 10.1638 1.89024 10.2397 1.92175C10.3156 1.95326 10.3846 1.99944 10.4426 2.05764L17.9426 9.55764C18.0008 9.6157 18.047 9.68467 18.0785 9.7606C18.11 9.83653 18.1263 9.91793 18.1263 10.0001C18.1263 10.0823 18.11 10.1638 18.0785 10.2397C18.047 10.3156 18.0008 10.3846 17.9426 10.4426L10.4426 17.9426C10.3253 18.06 10.1661 18.1259 10.0001 18.1259C9.83417 18.1259 9.675 18.06 9.55764 17.9426C9.44028 17.8253 9.37435 17.6661 9.37435 17.5001C9.37435 17.3342 9.44028 17.175 9.55764 17.0576L16.6164 10.0001L9.55764 2.94264C9.49944 2.88458 9.45326 2.81561 9.42175 2.73968C9.39024 2.66375 9.37402 2.58235 9.37402 2.50014C9.37402 2.41793 9.39024 2.33653 9.42175 2.2606C9.45326 2.18467 9.49944 2.1157 9.55764 2.05764Z"
      fill="white"
    />
  </svg>
</template>

<style></style>
