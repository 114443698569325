<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1883_7221)">
      <path
        d="M3.9375 0C4.08668 0 4.22976 0.0592632 4.33525 0.164752C4.44074 0.270242 4.5 0.413316 4.5 0.5625V1.125H13.5V0.5625C13.5 0.413316 13.5593 0.270242 13.6648 0.164752C13.7702 0.0592632 13.9133 0 14.0625 0C14.2117 0 14.3548 0.0592632 14.4602 0.164752C14.5657 0.270242 14.625 0.413316 14.625 0.5625V1.125H15.75C16.3467 1.125 16.919 1.36205 17.341 1.78401C17.7629 2.20597 18 2.77826 18 3.375V15.75C18 16.3467 17.7629 16.919 17.341 17.341C16.919 17.7629 16.3467 18 15.75 18H2.25C1.65326 18 1.08097 17.7629 0.65901 17.341C0.237053 16.919 0 16.3467 0 15.75V3.375C0 2.77826 0.237053 2.20597 0.65901 1.78401C1.08097 1.36205 1.65326 1.125 2.25 1.125H3.375V0.5625C3.375 0.413316 3.43426 0.270242 3.53975 0.164752C3.64524 0.0592632 3.78832 0 3.9375 0V0ZM2.25 2.25C1.95163 2.25 1.66548 2.36853 1.4545 2.5795C1.24353 2.79048 1.125 3.07663 1.125 3.375V15.75C1.125 16.0484 1.24353 16.3345 1.4545 16.5455C1.66548 16.7565 1.95163 16.875 2.25 16.875H15.75C16.0484 16.875 16.3345 16.7565 16.5455 16.5455C16.7565 16.3345 16.875 16.0484 16.875 15.75V3.375C16.875 3.07663 16.7565 2.79048 16.5455 2.5795C16.3345 2.36853 16.0484 2.25 15.75 2.25H2.25Z"
        fill="#48BEEA"
      />
      <path
        d="M2.8125 4.5C2.8125 4.35082 2.87176 4.20774 2.97725 4.10225C3.08274 3.99676 3.22582 3.9375 3.375 3.9375H14.625C14.7742 3.9375 14.9173 3.99676 15.0227 4.10225C15.1282 4.20774 15.1875 4.35082 15.1875 4.5V5.625C15.1875 5.77418 15.1282 5.91726 15.0227 6.02275C14.9173 6.12824 14.7742 6.1875 14.625 6.1875H3.375C3.22582 6.1875 3.08274 6.12824 2.97725 6.02275C2.87176 5.91726 2.8125 5.77418 2.8125 5.625V4.5ZM10.125 9C10.125 8.70163 10.2435 8.41548 10.4545 8.2045C10.6655 7.99353 10.9516 7.875 11.25 7.875H16.875V10.125H11.25C10.9516 10.125 10.6655 10.0065 10.4545 9.7955C10.2435 9.58452 10.125 9.29837 10.125 9ZM1.125 11.25H5.625C5.92337 11.25 6.20952 11.3685 6.4205 11.5795C6.63147 11.7905 6.75 12.0766 6.75 12.375C6.75 12.6734 6.63147 12.9595 6.4205 13.1705C6.20952 13.3815 5.92337 13.5 5.625 13.5H1.125V11.25Z"
        fill="#48BEEA"
      />
    </g>
    <defs>
      <clipPath id="clip0_1883_7221">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
