<template lang="">
  <div>
    <div class="flex align-center mt-3">
      <div>
        <check-box
          class="mr-4"
          :selected="itemIsSelected(order)"
          @click.native="toggleItemSelection(order)"
        />
      </div>

      <div class="grow ws-shadow rounded-lg bg-white z-10 flex">
        <div class="grow py-3 pl-7 pr-10">
          <table class="w-full">
            <thead class="text-gray-8 font-heading font-medium text-sm">
              <tr class="whitespace-nowrap">
                <th
                  class="pb-4 text-center"
                  v-for="({ key, title }, i) in headers"
                  :key="i"
                >
                  {{ title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="whitespace-nowrap">
                <td
                  class="text-center first:text-info font-standard font-semibold text-sm text-primary"
                  v-for="({ key }, i) in headers"
                  :key="i"
                  :class="{
                    capitalize: key === 'delivery_day',
                  }"
                >
                  <div
                    v-if="key === 'attachments'"
                    class="flex align-center justify-center"
                  >
                    <div class="cursor-pointer">
                      <span
                        class="font-heading font-semibold text-base text-info mr-2"
                        >Invoice</span
                      >
                      <v-icon>$vuetify.icons.download</v-icon>
                    </div>
                    <div class="cursor-pointer mx-5">
                      <span
                        class="font-heading font-semibold text-base text-info mr-2"
                        >AWB</span
                      >
                      <v-icon>$vuetify.icons.download</v-icon>
                    </div>
                  </div>

                  <div v-else>{{ getValueFromOrder(key) }}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex mt-6 pr-15">
            <div
              class="text-info font-heading font-semibold text-base mr-3 pt-1"
            >
              Update Status:
            </div>

            <order-status :status="order.status.value" :orderId="order.id" />
          </div>
        </div>
        <v-menu bottom left rounded="lg">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="align-self-center mr-1" v-bind="attrs" v-on="on">
              <v-icon>$vuetify.icons.more</v-icon>
            </v-btn></template
          >

          <v-list dense class="px-2 pr-4 relative">
            <v-icon size="18" class="menu-icon">$vuetify.icons.more</v-icon>
            <v-list-item
              class="bg-info-100 m-1 rounded-lg text-center cursor-pointer font-heading font-normal text-sm"
              v-for="({ title, action }, i) in items"
              :key="i"
              @click="action(order.id, order.status.value)"
            >
              <v-list-item-title>{{ title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div
        @click="$router.push({ name: 'show-order', params: { id: order.id } })"
        class="bg-primary w-12 text-center rounded-lg align-self-stretch -ml-2 cursor-pointer"
      >
        <div class="flex align-center justify-center h-full">
          <v-icon>$vuetify.icons.doubleArrow</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CheckBox from "./CheckBox.vue";
import OrderStatus from "@/components/Order/OrderStatus.vue";
import { adminApi } from "@/api";
import { eventBus } from "@/mixins";
export default {
  components: { CheckBox, OrderStatus },
  props: {
    order: { type: Object, default: () => ({}) },
    toggleItemSelection: { type: Function },
    itemIsSelected: { type: Function },
  },
  data() {
    return {
      selected: true,
      headers: [
        { key: "vendor_order_id", title: "Order ID" },
        { key: "created", title: "Created At" },
        { key: "vendor_id", title: "Merchant ID" },
        { key: "delivery_day", title: "Delivery Speed" },
        { key: "skus", title: "SKUs" },
        { key: "payment_method", title: "Payment Type" },
        { key: "total", title: "Total Price" },
        { key: "attachments", title: "Attachments" },
      ],
      items: [
        {
          title: "View Order",
          action: (id) => {
            this.$router.push({ name: "show-order", params: { id } });
          },
        },
        {
          title: "Set as picked-packed",
          action: (id, status) => {
            this.updateOrderStatus(id, status);
          },
        },
        {
          title: "Download AWB",
          action: (id) => {
            return;
          },
        },
        {
          title: "Download Invoice",
          action: (id) => {
            return;
          },
        },
        {
          title: "Report an issue",
          action: (id) => {
            return;
          },
        },
      ],
    };
  },

  methods: {
    getValueFromOrder(key) {
      switch (key) {
        case "skus":
          return this.order?.items?.length;

        case "payment_method":
          return this.order?.payment_method?.text;
        case "created":
          return this.formatTime(this.order[key], "DD - MM - YYYY");
        default:
          return this.order[key];
      }
    },
    async updateOrderStatus(orderId, status) {
      if (status !== 1) {
        this.$toast.warning(
          "Your order status is already set to picked-packed"
        );
        return;
      }

      const payload = [{ order_id: orderId, status: 2 }];

      try {
        const { data } = await adminApi.put("/spm/orders/status", {
          ...payload,
        });
        this.$toast.success("Your order status has been updated successfully");
        eventBus.$emit("statusUpdated");
      } catch (error) {
        this.$toast.error("Something went wrong,please try again");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.menu-icon {
  position: absolute;
  top: 12px;
  right: 0;
}
</style>
