<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.96659 17.3696C8.91723 17.3696 8.86907 17.3567 8.82598 17.331C8.75194 17.2854 8.70752 17.2082 8.70752 17.1239V7.67508C8.70752 7.57918 8.76675 7.49138 8.85933 7.45153L17.4445 3.7376C17.5246 3.70256 17.6184 3.70952 17.6924 3.75392C17.7665 3.7996 17.8109 3.87677 17.8109 3.96103V8.43802C17.8109 8.57377 17.695 8.68371 17.5518 8.68371C17.4087 8.68371 17.2928 8.57377 17.2928 8.43802V4.34256L9.22572 7.83333V16.7416L11.5589 15.7318C11.6896 15.6756 11.8426 15.7295 11.9019 15.8535C11.9611 15.9775 11.9043 16.1226 11.7735 16.1788L9.07389 17.3466C9.03948 17.3618 9.00239 17.3699 8.96664 17.3699L8.96659 17.3696Z"
      fill="#101820"
    />
    <path
      d="M3.46972 13.7574C3.43277 13.7574 3.39689 13.7505 3.36234 13.7352L1.38081 12.8787C1.28823 12.8389 1.229 12.7511 1.229 12.6551V10.9419C1.229 10.8588 1.27343 10.7805 1.34747 10.7348C1.4215 10.6892 1.51529 10.6834 1.59546 10.7185L3.57698 11.5763C3.66956 11.616 3.72879 11.7038 3.72879 11.7998V13.513C3.72879 13.5961 3.68437 13.6744 3.61033 13.7201C3.56711 13.7445 3.51775 13.7574 3.46972 13.7574V13.7574ZM1.74723 12.4971L3.21052 13.1302V11.9576L1.74723 11.3245V12.4971Z"
      fill="#101820"
    />
    <path
      d="M8.96654 17.3694C8.92959 17.3694 8.8937 17.3625 8.85915 17.3472L0.274368 13.6332C0.181789 13.5935 0.122559 13.5057 0.122559 13.4097V3.95953C0.122559 3.87641 0.166982 3.79809 0.24102 3.75242C0.315059 3.70675 0.40884 3.70093 0.489012 3.7361L9.07414 7.45003C9.16672 7.48976 9.22595 7.57756 9.22595 7.67358V17.1224C9.22595 17.2056 9.18153 17.2839 9.10749 17.3295C9.06427 17.3565 9.01491 17.3693 8.96675 17.3693L8.96654 17.3694ZM0.640617 13.2516L8.70734 16.7421L8.70747 7.83247L0.64075 4.34202L0.640617 13.2516Z"
      fill="#101820"
    />
    <path
      d="M5.4132 8.55819C5.37745 8.55819 5.34036 8.55123 5.30581 8.53593L3.40444 7.71334C3.27371 7.65716 3.21688 7.51091 3.27611 7.38806C3.33534 7.26408 3.48955 7.21018 3.61909 7.26636L5.52046 8.08895C5.65119 8.14512 5.70802 8.29137 5.64879 8.41422C5.6057 8.50443 5.51192 8.55819 5.4132 8.55819H5.4132Z"
      fill="#101820"
    />
    <path
      d="M17.5499 4.20542C17.5142 4.20542 17.4771 4.19846 17.4426 4.18315L8.96602 0.515861L0.48847 4.18315C0.357738 4.23932 0.204727 4.18543 0.145496 4.06144C0.0862648 3.93746 0.143094 3.79234 0.273827 3.73617L8.85861 0.0219187C8.92652 -0.00730624 9.00549 -0.00730624 9.07326 0.0219187L17.6584 3.73585C17.7891 3.79202 17.8459 3.93827 17.7867 4.06112C17.7423 4.15246 17.6485 4.20522 17.5498 4.20522L17.5499 4.20542Z"
      fill="#101820"
    />
    <path
      d="M3.51158 5.56037C3.41287 5.56037 3.31909 5.5066 3.27587 5.4164C3.21664 5.29241 3.27347 5.1473 3.4042 5.09112L11.9893 1.3772C12.1201 1.32102 12.2731 1.37492 12.3323 1.4989C12.3915 1.62289 12.3347 1.768 12.204 1.82418L3.61918 5.5381C3.58463 5.55341 3.54755 5.56037 3.5118 5.56037H3.51158Z"
      fill="#101820"
    />
    <path
      d="M5.41312 6.3828C5.26998 6.3828 5.15405 6.27286 5.15405 6.13711C5.15405 6.03527 5.21942 5.94759 5.312 5.91014L13.8896 2.19849C14.0203 2.14231 14.1734 2.19621 14.2326 2.32019C14.2918 2.44418 14.235 2.58929 14.1043 2.64547L5.5205 6.36069C5.48728 6.37473 5.45019 6.38295 5.41324 6.38295L5.41312 6.3828Z"
      fill="#101820"
    />
    <path
      d="M5.41325 8.55829C5.27011 8.55829 5.15419 8.44835 5.15419 8.3126L5.15405 6.13729C5.15405 6.00154 5.26998 5.8916 5.41312 5.8916C5.55625 5.8916 5.67218 6.00154 5.67218 6.13729V8.3126C5.67231 8.44835 5.55639 8.55829 5.41325 8.55829Z"
      fill="#101820"
    />
    <path
      d="M3.51175 7.73554C3.36861 7.73554 3.25269 7.6256 3.25269 7.48984V5.31454C3.25269 5.17879 3.36861 5.06885 3.51175 5.06885C3.65489 5.06885 3.77081 5.17879 3.77081 5.31454V7.48984C3.77081 7.6256 3.65489 7.73554 3.51175 7.73554Z"
      fill="#101820"
    />
    <path
      d="M18.3224 16.651H12.0842C11.7104 16.651 11.4067 16.3632 11.4067 16.0086L11.4069 8.83337C11.4069 8.47888 11.7104 8.19092 12.0843 8.19092H13.5278C13.671 8.19092 13.7869 8.30086 13.7869 8.43661C13.7869 8.57236 13.671 8.6823 13.5278 8.6823H12.0843C11.9966 8.6823 11.9251 8.75012 11.9251 8.83324V16.0085C11.9251 16.0916 11.9966 16.1594 12.0843 16.1594H18.3226C18.4102 16.1594 18.4817 16.0916 18.4817 16.0085V8.83324C18.4817 8.75012 18.4102 8.6823 18.3226 8.6823H16.879C16.7359 8.6823 16.62 8.57236 16.62 8.43661C16.62 8.30086 16.7359 8.19092 16.879 8.19092H18.3226C18.6964 8.19092 19 8.47874 19 8.83337V16.0086C18.9999 16.3629 18.6964 16.6508 18.3224 16.6508L18.3224 16.651Z"
      fill="#101820"
    />
    <path
      d="M16.0105 8.21188C15.8674 8.21188 15.7515 8.10194 15.7515 7.96619V7.54149C15.7515 7.53099 15.7428 7.52277 15.7317 7.52277H14.6756C14.6645 7.52277 14.6558 7.53099 14.6558 7.54149V7.96619C14.6558 8.10194 14.5399 8.21188 14.3968 8.21188C14.2536 8.21188 14.1377 8.10194 14.1377 7.96619V7.54149C14.1377 7.26062 14.3796 7.03125 14.6757 7.03125H15.7318C16.028 7.03125 16.2699 7.26062 16.2699 7.54149V7.96619C16.2696 8.10194 16.1537 8.21188 16.0105 8.21188H16.0105Z"
      fill="#101820"
    />
    <path
      d="M16.3855 9.32117H14.0214C13.6068 9.32117 13.2688 9.00058 13.2688 8.60737V8.43304C13.2688 8.03981 13.6068 7.71924 14.0214 7.71924H16.3855C16.8001 7.71924 17.1381 8.03983 17.1381 8.43304V8.60737C17.1381 9.0006 16.8001 9.32117 16.3855 9.32117ZM14.0214 8.21188C13.8919 8.21188 13.7871 8.31133 13.7871 8.43417V8.60851C13.7871 8.73136 13.8919 8.8308 14.0214 8.8308H16.3855C16.515 8.8308 16.6198 8.73136 16.6198 8.60851V8.43417C16.6198 8.31133 16.515 8.21188 16.3855 8.21188H14.0214Z"
      fill="#101820"
    />
    <path
      d="M17.4304 11.3268H14.4224C14.2793 11.3268 14.1633 11.2169 14.1633 11.0811C14.1633 10.9454 14.2793 10.8354 14.4224 10.8354H17.4304C17.5736 10.8354 17.6895 10.9454 17.6895 11.0811C17.6896 11.2169 17.5736 11.3268 17.4304 11.3268Z"
      fill="#101820"
    />
    <path
      d="M17.4304 12.6667H14.4224C14.2793 12.6667 14.1633 12.5567 14.1633 12.421C14.1633 12.2852 14.2793 12.1753 14.4224 12.1753H17.4304C17.5736 12.1753 17.6895 12.2852 17.6895 12.421C17.6896 12.5567 17.5736 12.6667 17.4304 12.6667Z"
      fill="#101820"
    />
    <path
      d="M17.4304 14.0065H14.4224C14.2793 14.0065 14.1633 13.8966 14.1633 13.7608C14.1633 13.6251 14.2793 13.5151 14.4224 13.5151H17.4304C17.5736 13.5151 17.6895 13.6251 17.6895 13.7608C17.6896 13.8965 17.5736 14.0065 17.4304 14.0065Z"
      fill="#101820"
    />
    <path
      d="M13.597 11.081C13.597 11.2439 13.4577 11.3759 13.2861 11.3759C13.1144 11.3759 12.9751 11.2439 12.9751 11.081C12.9751 10.9182 13.1144 10.7861 13.2861 10.7861C13.4577 10.7861 13.597 10.9182 13.597 11.081Z"
      fill="#101820"
    />
    <path
      d="M13.597 12.4209C13.597 12.5837 13.4577 12.7158 13.2861 12.7158C13.1144 12.7158 12.9751 12.5837 12.9751 12.4209C12.9751 12.2581 13.1144 12.126 13.2861 12.126C13.4577 12.126 13.597 12.2581 13.597 12.4209Z"
      fill="#101820"
    />
    <path
      d="M13.597 13.7606C13.597 13.9236 13.4577 14.0555 13.2861 14.0555C13.1144 14.0555 12.9751 13.9236 12.9751 13.7606C12.9751 13.5978 13.1144 13.4658 13.2861 13.4658C13.4577 13.4658 13.597 13.5978 13.597 13.7606Z"
      fill="#101820"
    />
    <path
      d="M0.0406164 17.9262C0.038482 17.9273 0.0360808 17.9281 0.0336797 17.9288C0.0315453 17.9294 0.0291442 17.9298 0.026743 17.9298C0.0188723 17.9298 0.0128693 17.9278 0.00860051 17.9237C0.00433168 17.9197 0.00219727 17.9144 0.00219727 17.9075C0.00219727 17.9008 0.00433168 17.8954 0.00860051 17.8913C0.0128693 17.8873 0.0188723 17.8853 0.026743 17.8853C0.0291442 17.8853 0.0315453 17.8856 0.0336797 17.8863C0.0360809 17.8866 0.038482 17.8873 0.0406164 17.8883V17.8974C0.038482 17.8961 0.0363476 17.8951 0.0342131 17.8944C0.0320787 17.8937 0.0296776 17.8934 0.0272765 17.8934C0.0232744 17.8934 0.0200727 17.8947 0.0176716 17.8974C0.015137 17.8998 0.0139362 17.9032 0.0139362 17.9075C0.0139362 17.9119 0.0151368 17.9155 0.0176716 17.9181C0.0200728 17.9206 0.0232744 17.9217 0.0272765 17.9217C0.0296777 17.9217 0.0320787 17.9214 0.0342131 17.9207C0.0363476 17.92 0.038482 17.919 0.0406164 17.9176V17.9262Z"
      fill="#101820"
    />
    <path
      d="M0.0746399 17.9056C0.0735727 17.9054 0.0725055 17.905 0.0714383 17.9046C0.0706379 17.9043 0.0698375 17.9041 0.0687703 17.9041C0.0658354 17.9041 0.063701 17.905 0.062367 17.9066C0.0608996 17.9084 0.0602326 17.9109 0.0602326 17.9142V17.9289H0.0495605V17.897H0.0602326V17.9021C0.0615666 17.9 0.0631675 17.8985 0.0650349 17.8975C0.0667691 17.8965 0.0689035 17.896 0.0714381 17.896H0.0725053H0.0746397L0.0746399 17.9056Z"
      fill="#101820"
    />
    <path
      d="M0.113434 17.9127V17.9157H0.0883546C0.0883546 17.9181 0.0890216 17.92 0.090489 17.9213C0.0922232 17.9223 0.0944912 17.9228 0.0974257 17.9228C0.099827 17.9228 0.102228 17.9226 0.104362 17.9218C0.106764 17.9212 0.109298 17.9203 0.111833 17.9193V17.9269C0.109298 17.9279 0.10663 17.9286 0.103829 17.9289C0.101294 17.9295 0.0987593 17.9299 0.0963582 17.9299C0.0902216 17.9299 0.0854194 17.9284 0.0819507 17.9253C0.0787491 17.9223 0.0771484 17.9181 0.0771484 17.9127C0.0771484 17.9076 0.0787492 17.9036 0.0819507 17.9006C0.085419 17.8975 0.0900881 17.896 0.0958244 17.896C0.10116 17.896 0.105429 17.8975 0.108631 17.9006C0.111833 17.9036 0.113433 17.9076 0.113433 17.9127L0.113434 17.9127ZM0.102229 17.9092C0.102229 17.9075 0.101428 17.9061 0.100094 17.9051C0.099027 17.9038 0.0975596 17.9031 0.0958254 17.9031C0.093691 17.9031 0.0918233 17.9036 0.0904894 17.9046C0.0894222 17.9056 0.0886218 17.9071 0.088355 17.9092L0.102229 17.9092Z"
      fill="#101820"
    />
    <path
      d="M0.136749 17.9142C0.134615 17.9142 0.132747 17.9147 0.131413 17.9157C0.130346 17.9165 0.129813 17.9175 0.129813 17.9188C0.129813 17.9202 0.130346 17.9212 0.131413 17.9218C0.132481 17.9226 0.133681 17.9228 0.135149 17.9228C0.137283 17.9228 0.139017 17.9222 0.140485 17.9208C0.141819 17.9195 0.142619 17.9178 0.142619 17.9157V17.9142H0.136749ZM0.153291 17.9107V17.9289H0.142619V17.9243C0.141152 17.9261 0.139418 17.9274 0.137283 17.9284C0.135416 17.9294 0.133281 17.9299 0.13088 17.9299C0.127278 17.9299 0.124477 17.9289 0.122342 17.9269C0.120208 17.9248 0.119141 17.9223 0.119141 17.9193C0.119141 17.9156 0.120341 17.9129 0.122876 17.9112C0.125677 17.9092 0.13008 17.9081 0.136216 17.9081H0.142619V17.9076C0.142619 17.906 0.141819 17.9049 0.140485 17.9041C0.139017 17.9035 0.136883 17.9031 0.134082 17.9031C0.131947 17.9031 0.129813 17.9033 0.127678 17.9036C0.125544 17.904 0.123676 17.9046 0.122342 17.9056V17.8975C0.124477 17.8973 0.126611 17.8969 0.128745 17.8965C0.131147 17.8962 0.133681 17.896 0.136216 17.896C0.142219 17.896 0.146488 17.8973 0.149022 17.8995C0.151824 17.9019 0.153291 17.9056 0.153291 17.9107Z"
      fill="#101820"
    />
    <path
      d="M0.175187 17.8877V17.8968H0.186393V17.9039H0.175187V17.9176C0.175187 17.9189 0.175321 17.92 0.175721 17.9206C0.176388 17.921 0.177855 17.9211 0.17999 17.9211H0.185326V17.9287H0.176255C0.171986 17.9287 0.168918 17.9279 0.167184 17.9262C0.165316 17.9245 0.164516 17.9216 0.164516 17.9176V17.9039H0.15918V17.8968H0.164516V17.8877L0.175187 17.8877Z"
      fill="#101820"
    />
    <path
      d="M0.226471 17.9127V17.9157H0.201392C0.201392 17.9181 0.202059 17.92 0.203526 17.9213C0.20526 17.9223 0.207528 17.9228 0.210463 17.9228C0.212864 17.9228 0.215265 17.9226 0.2174 17.9218C0.219801 17.9212 0.222335 17.9203 0.22487 17.9193V17.9269C0.222335 17.9279 0.219667 17.9286 0.216866 17.9289C0.214331 17.9295 0.211796 17.9299 0.209395 17.9299C0.203259 17.9299 0.198456 17.9284 0.194988 17.9253C0.191786 17.9223 0.190186 17.9181 0.190186 17.9127C0.190186 17.9076 0.191786 17.9036 0.194988 17.9006C0.198456 17.8975 0.203125 17.896 0.208862 17.896C0.214198 17.896 0.218466 17.8975 0.221668 17.9006C0.22487 17.9036 0.22647 17.9076 0.22647 17.9127L0.226471 17.9127ZM0.215266 17.9092C0.215266 17.9075 0.214465 17.9061 0.213131 17.9051C0.212064 17.9038 0.210597 17.9031 0.208863 17.9031C0.206728 17.9031 0.20486 17.9036 0.203526 17.9046C0.202459 17.9056 0.201659 17.9071 0.201392 17.9092L0.215266 17.9092Z"
      fill="#101820"
    />
    <path
      d="M0.257791 17.9015V17.8843H0.268463V17.9288H0.257791V17.9243C0.256323 17.926 0.254722 17.9273 0.252988 17.9283C0.251121 17.9293 0.248986 17.9298 0.246585 17.9298C0.242316 17.9298 0.238715 17.9283 0.235913 17.9253C0.233379 17.922 0.232178 17.9177 0.232178 17.9126C0.232178 17.9079 0.233378 17.904 0.235913 17.901C0.238715 17.8977 0.242316 17.8959 0.246585 17.8959C0.248986 17.8959 0.251121 17.8964 0.252988 17.8974C0.254723 17.8982 0.256323 17.8995 0.257791 17.9015V17.9015ZM0.250854 17.9222C0.252988 17.9222 0.254589 17.9215 0.255656 17.9197C0.25699 17.9181 0.257791 17.9157 0.257791 17.9126C0.257791 17.9096 0.25699 17.9074 0.255656 17.906C0.254589 17.9044 0.252988 17.9035 0.250854 17.9035C0.248319 17.9035 0.246318 17.9044 0.244984 17.906C0.243917 17.9074 0.243383 17.9096 0.243383 17.9126C0.243383 17.9157 0.243917 17.9181 0.244984 17.9197C0.246318 17.9215 0.248319 17.9222 0.250854 17.9222Z"
      fill="#101820"
    />
    <path
      d="M0.31868 17.9222C0.320814 17.9222 0.322415 17.9215 0.323482 17.9197C0.324816 17.9181 0.325616 17.9157 0.325616 17.9126C0.325616 17.9096 0.324816 17.9074 0.323482 17.906C0.322415 17.9044 0.320814 17.9035 0.31868 17.9035C0.316145 17.9035 0.314144 17.9044 0.31281 17.906C0.311743 17.9074 0.311209 17.9096 0.311209 17.9126C0.311209 17.9157 0.311743 17.9181 0.31281 17.9197C0.314144 17.9215 0.316145 17.9222 0.31868 17.9222ZM0.311209 17.9015C0.312543 17.8995 0.314144 17.8982 0.316011 17.8974C0.317746 17.8964 0.31988 17.8959 0.322415 17.8959C0.326684 17.8959 0.330019 17.8977 0.332553 17.901C0.335355 17.904 0.336822 17.9079 0.336822 17.9126C0.336822 17.9177 0.335355 17.922 0.332553 17.9253C0.330019 17.9283 0.326684 17.9298 0.322415 17.9298C0.31988 17.9298 0.317746 17.9293 0.316011 17.9283C0.314144 17.9273 0.312543 17.926 0.311209 17.9243V17.9288H0.300537V17.8843H0.311209V17.9015Z"
      fill="#101820"
    />
    <path
      d="M0.339844 17.897H0.351049L0.36012 17.9187L0.367591 17.897H0.378796L0.364389 17.9319C0.362921 17.9356 0.361187 17.9381 0.359053 17.9395C0.357185 17.9407 0.354784 17.9415 0.351582 17.9415H0.345713V17.9349H0.348914C0.350648 17.9349 0.351849 17.9345 0.35265 17.9339C0.353717 17.9332 0.354384 17.9321 0.354784 17.9309L0.355318 17.9299L0.339844 17.897Z"
      fill="#101820"
    />
    <path
      d="M0.437578 17.8873V17.8964C0.435444 17.8954 0.433043 17.8947 0.430642 17.8944C0.428107 17.8937 0.42584 17.8934 0.423705 17.8934C0.42117 17.8934 0.41917 17.8937 0.417835 17.8944C0.416368 17.8951 0.415701 17.8963 0.415701 17.8979C0.415701 17.8989 0.415968 17.8998 0.416768 17.9004C0.417835 17.9012 0.419569 17.9017 0.422104 17.902L0.426906 17.903C0.431842 17.904 0.435444 17.9055 0.437578 17.9075C0.439713 17.9093 0.44078 17.9119 0.44078 17.9156C0.44078 17.9204 0.439179 17.924 0.435978 17.9262C0.433043 17.9286 0.428641 17.9298 0.422638 17.9298C0.419703 17.9298 0.416902 17.9294 0.4141 17.9288C0.411165 17.9285 0.408364 17.9278 0.405563 17.9268V17.9171C0.408364 17.9185 0.411032 17.9197 0.413567 17.9207C0.416368 17.9214 0.419036 17.9217 0.421571 17.9217C0.423972 17.9217 0.425973 17.9214 0.427441 17.9207C0.428775 17.9197 0.429575 17.9185 0.429575 17.9171C0.429575 17.9159 0.429042 17.9149 0.427974 17.9141C0.427174 17.9131 0.42544 17.9125 0.422638 17.9121L0.41837 17.9111C0.4137 17.9101 0.410365 17.9085 0.408231 17.9065C0.406097 17.9045 0.405029 17.9018 0.405029 17.8984C0.405029 17.8941 0.406363 17.8908 0.409298 17.8888C0.4125 17.8865 0.416769 17.8853 0.422105 17.8853C0.424506 17.8853 0.42704 17.8855 0.429575 17.8858C0.432376 17.8861 0.435045 17.8866 0.437579 17.8873L0.437578 17.8873Z"
      fill="#101820"
    />
    <path
      d="M0.47835 17.9212H0.460208L0.457006 17.9288H0.445801L0.462343 17.8857H0.476216L0.492758 17.9288H0.481019L0.47835 17.9212ZM0.462876 17.9131H0.475149L0.469279 17.8959L0.462876 17.9131Z"
      fill="#101820"
    />
    <path
      d="M0.499023 17.8857H0.513431L0.524103 17.909L0.534242 17.8857H0.549183V17.9288H0.537977V17.8974L0.527838 17.9207H0.520368L0.509696 17.8974V17.9288H0.499024L0.499023 17.8857Z"
      fill="#101820"
    />
    <path
      d="M0.592993 17.8943V17.9207H0.597261C0.602197 17.9207 0.605933 17.9195 0.608467 17.9171C0.610868 17.9148 0.612202 17.9116 0.612202 17.9075C0.612202 17.9032 0.610868 17.8999 0.608467 17.8979C0.605932 17.8956 0.602197 17.8943 0.597261 17.8943L0.592993 17.8943ZM0.581787 17.8857H0.59406C0.60073 17.8857 0.605799 17.8862 0.609001 17.8873C0.612469 17.8883 0.615538 17.8898 0.618072 17.8918C0.619806 17.8938 0.621274 17.8962 0.622341 17.8989C0.623408 17.9013 0.623942 17.9042 0.623942 17.9075C0.623942 17.9105 0.623408 17.9134 0.622341 17.9161C0.621274 17.9185 0.619806 17.9207 0.618072 17.9227C0.615538 17.9251 0.612469 17.9267 0.609001 17.9277C0.605799 17.9285 0.60073 17.9288 0.59406 17.9288H0.581787V17.8857Z"
      fill="#101820"
    />
    <path
      d="M0.666169 17.9127V17.9157H0.641089C0.641089 17.9181 0.641756 17.92 0.643223 17.9213C0.644958 17.9223 0.647226 17.9228 0.65016 17.9228C0.652561 17.9228 0.654962 17.9226 0.657097 17.9218C0.659498 17.9212 0.662033 17.9203 0.664567 17.9193V17.9269C0.662033 17.9279 0.659365 17.9286 0.656563 17.9289C0.654028 17.9295 0.651494 17.9299 0.649093 17.9299C0.642956 17.9299 0.638154 17.9284 0.634685 17.9253C0.631483 17.9223 0.629883 17.9181 0.629883 17.9127C0.629883 17.9076 0.631484 17.9036 0.634685 17.9006C0.638153 17.8975 0.642822 17.896 0.648559 17.896C0.653895 17.896 0.658164 17.8975 0.661365 17.9006C0.664567 17.9036 0.666168 17.9076 0.666168 17.9127L0.666169 17.9127ZM0.654963 17.9092C0.654963 17.9075 0.654163 17.9061 0.652829 17.9051C0.651761 17.9038 0.650294 17.9031 0.64856 17.9031C0.646425 17.9031 0.644558 17.9036 0.643224 17.9046C0.642157 17.9056 0.641356 17.9071 0.641089 17.9092L0.654963 17.9092Z"
      fill="#101820"
    />
    <path
      d="M0.700401 17.898V17.9056C0.698266 17.9046 0.696132 17.904 0.693997 17.9036C0.691863 17.9033 0.689729 17.9031 0.687594 17.9031C0.685727 17.9031 0.684393 17.9033 0.683325 17.9036C0.682258 17.904 0.681725 17.9046 0.681725 17.9056C0.681725 17.9066 0.681991 17.9074 0.682792 17.9076C0.683459 17.908 0.684926 17.9084 0.687061 17.9086L0.688661 17.9092C0.694264 17.9099 0.698 17.9111 0.699867 17.9127C0.702001 17.9141 0.703068 17.9162 0.703068 17.9193C0.703068 17.9227 0.701601 17.9253 0.6988 17.9274C0.696265 17.929 0.692396 17.9299 0.68706 17.9299C0.684526 17.9299 0.681991 17.9296 0.67959 17.9294C0.677456 17.929 0.675054 17.9286 0.672653 17.9279V17.9198C0.674788 17.9208 0.676922 17.9217 0.679056 17.9223C0.681458 17.9227 0.683859 17.9228 0.685993 17.9228C0.688128 17.9228 0.689729 17.9227 0.690795 17.9223C0.691863 17.9217 0.692396 17.9208 0.692396 17.9198C0.692396 17.9188 0.691996 17.9181 0.691329 17.9178C0.690529 17.9171 0.689195 17.9167 0.68706 17.9167L0.684926 17.9162C0.680257 17.9156 0.676922 17.9146 0.674787 17.9132C0.67292 17.9116 0.672119 17.9092 0.672119 17.9061C0.672119 17.9028 0.67332 17.9003 0.675855 17.8985C0.678256 17.8969 0.681991 17.896 0.68706 17.896C0.689194 17.896 0.691329 17.8962 0.693463 17.8965C0.695598 17.8969 0.697866 17.8974 0.7004 17.898H0.700401Z"
      fill="#101820"
    />
    <path
      d="M0.710938 17.8969H0.72161V17.9288H0.710938V17.8969ZM0.710938 17.8843H0.72161V17.8924H0.710938V17.8843Z"
      fill="#101820"
    />
    <path
      d="M0.755105 17.9233C0.753638 17.9253 0.752037 17.9269 0.750303 17.9279C0.748435 17.9286 0.746301 17.9289 0.7439 17.9289C0.739631 17.9289 0.736029 17.9274 0.733228 17.9243C0.730693 17.9213 0.729492 17.9175 0.729492 17.9127C0.729492 17.9076 0.730693 17.9036 0.733228 17.9006C0.736029 17.8975 0.739631 17.896 0.7439 17.896C0.746301 17.896 0.748435 17.8965 0.750303 17.8975C0.752037 17.8983 0.753638 17.8995 0.755105 17.9016V17.897H0.765777V17.9259C0.765777 17.9309 0.76391 17.9347 0.760441 17.9375C0.75724 17.9402 0.752437 17.9415 0.746034 17.9415C0.743899 17.9415 0.741765 17.9413 0.73963 17.941C0.737763 17.9407 0.735895 17.9402 0.733761 17.9395V17.9319C0.735495 17.9329 0.737229 17.9336 0.739097 17.934C0.740831 17.9346 0.742832 17.935 0.744967 17.935C0.748435 17.935 0.75097 17.9341 0.752437 17.9324C0.754171 17.931 0.755105 17.9289 0.755105 17.9259L0.755105 17.9233ZM0.748168 17.9036C0.745634 17.9036 0.743633 17.9045 0.742299 17.9061C0.741231 17.9075 0.740698 17.9097 0.740698 17.9127C0.740698 17.9155 0.741231 17.9176 0.742299 17.9193C0.743633 17.9207 0.745634 17.9213 0.748168 17.9213C0.750303 17.9213 0.751904 17.9207 0.752971 17.9193C0.754305 17.9176 0.755105 17.9155 0.755105 17.9127C0.755105 17.9097 0.754305 17.9075 0.752971 17.9061C0.751904 17.9045 0.750303 17.9036 0.748168 17.9036Z"
      fill="#101820"
    />
    <path
      d="M0.810518 17.9092V17.9289H0.799312V17.9137C0.799312 17.9111 0.799045 17.9092 0.798779 17.9081C0.798779 17.9071 0.798512 17.9065 0.798245 17.9061C0.797845 17.9055 0.797311 17.905 0.796644 17.9046C0.795844 17.904 0.795177 17.9036 0.79451 17.9036C0.791975 17.9036 0.789974 17.9045 0.78864 17.9061C0.787573 17.9079 0.787039 17.9102 0.787039 17.9132V17.9289H0.776367V17.897H0.787039V17.9016C0.788773 17.8995 0.790508 17.8983 0.792375 17.8975C0.794109 17.8965 0.796111 17.896 0.798245 17.896C0.802114 17.896 0.805182 17.8973 0.807316 17.8995C0.809451 17.9019 0.810518 17.9051 0.810518 17.9092V17.9092Z"
      fill="#101820"
    />
    <path
      d="M0.846397 17.898V17.9056C0.844262 17.9046 0.842128 17.904 0.839993 17.9036C0.837859 17.9033 0.835725 17.9031 0.83359 17.9031C0.831723 17.9031 0.830389 17.9033 0.829321 17.9036C0.828254 17.904 0.827721 17.9046 0.827721 17.9056C0.827721 17.9066 0.827987 17.9074 0.828788 17.9076C0.829455 17.908 0.830922 17.9084 0.833057 17.9086L0.834657 17.9092C0.84026 17.9099 0.843996 17.9111 0.845863 17.9127C0.847997 17.9141 0.849065 17.9162 0.849065 17.9193C0.849065 17.9227 0.847597 17.9253 0.844796 17.9274C0.842261 17.929 0.838393 17.9299 0.833056 17.9299C0.830522 17.9299 0.827987 17.9296 0.825586 17.9294C0.823452 17.929 0.82105 17.9286 0.818649 17.9279V17.9198C0.820784 17.9208 0.822918 17.9217 0.825053 17.9223C0.827454 17.9227 0.829855 17.9228 0.831989 17.9228C0.834124 17.9228 0.835725 17.9227 0.836792 17.9223C0.837859 17.9217 0.838392 17.9208 0.838392 17.9198C0.838392 17.9188 0.837992 17.9181 0.837325 17.9178C0.836525 17.9171 0.835191 17.9167 0.833056 17.9167L0.830922 17.9162C0.826253 17.9156 0.822918 17.9146 0.820783 17.9132C0.818916 17.9116 0.818115 17.9092 0.818115 17.9061C0.818115 17.9028 0.819316 17.9003 0.821851 17.8985C0.824252 17.8969 0.827987 17.896 0.833056 17.896C0.835191 17.896 0.837325 17.8962 0.839459 17.8965C0.841594 17.8969 0.843862 17.8974 0.846396 17.898H0.846397Z"
      fill="#101820"
    />
    <path
      d="M0.0261466 17.9429V17.9495H0.0202768C0.0188093 17.9495 0.0177421 17.9498 0.0170751 17.9505C0.0166749 17.9508 0.0165415 17.9517 0.0165415 17.953V17.9555H0.0256126V17.9626H0.0165415V17.9874H0.00533604V17.9626H0V17.9555H0.00533604V17.953C0.00533604 17.9497 0.00640324 17.9472 0.00853766 17.9454C0.0106721 17.9438 0.0138737 17.9429 0.0181425 17.9429L0.0261466 17.9429Z"
      fill="#101820"
    />
    <path
      d="M0.0560852 17.9642C0.055018 17.964 0.0539508 17.9636 0.0528836 17.9632C0.0520832 17.9629 0.0512828 17.9627 0.0502156 17.9627C0.0472808 17.9627 0.0451463 17.9636 0.0438123 17.9652C0.0423449 17.967 0.0416779 17.9695 0.0416779 17.9728V17.9875H0.0310059V17.9556H0.0416779V17.9607C0.0430119 17.9586 0.0446128 17.9571 0.0464802 17.9561C0.0482144 17.9551 0.0503488 17.9546 0.0528834 17.9546H0.0539506H0.0560851L0.0560852 17.9642Z"
      fill="#101820"
    />
    <path
      d="M0.0775138 17.9622C0.0749792 17.9622 0.0729783 17.9631 0.071644 17.9647C0.0705768 17.9661 0.0700432 17.9683 0.0700432 17.9713C0.0700432 17.9743 0.0705768 17.9767 0.071644 17.9784C0.0729781 17.9801 0.0749791 17.9809 0.0775138 17.9809C0.079915 17.9809 0.0817827 17.9801 0.0828499 17.9784C0.0841839 17.9767 0.0849843 17.9743 0.0849843 17.9713C0.0849843 17.9683 0.0841839 17.9661 0.0828499 17.9647C0.0817827 17.9631 0.079915 17.9622 0.0775138 17.9622ZM0.0775138 17.9546C0.0831166 17.9546 0.0876525 17.9561 0.0908538 17.9591C0.0943221 17.9622 0.0961898 17.9662 0.0961898 17.9713C0.0961898 17.9767 0.0943222 17.9809 0.0908538 17.9839C0.0876521 17.987 0.0831166 17.9885 0.0775138 17.9885C0.0717776 17.9885 0.0671085 17.987 0.0636402 17.9839C0.0604385 17.9809 0.0588379 17.9767 0.0588379 17.9713C0.0588379 17.9662 0.0604387 17.9622 0.0636402 17.9591C0.0671085 17.9561 0.0717776 17.9546 0.0775138 17.9546Z"
      fill="#101820"
    />
    <path
      d="M0.135486 17.9607C0.136553 17.9586 0.137887 17.9571 0.139755 17.9561C0.141889 17.9551 0.144024 17.9546 0.146158 17.9546C0.150027 17.9546 0.153095 17.9559 0.155229 17.9581C0.157364 17.9605 0.158431 17.9637 0.158431 17.9677V17.9875H0.147225V17.9708V17.9703V17.9688C0.147225 17.9665 0.146825 17.9647 0.146158 17.9637C0.145358 17.9627 0.14429 17.9622 0.142956 17.9622C0.140822 17.9622 0.139221 17.9631 0.138154 17.9647C0.137087 17.9665 0.136553 17.9688 0.136553 17.9718V17.9875H0.125348V17.9708C0.125348 17.9671 0.124948 17.9647 0.124281 17.9637C0.12388 17.9627 0.122813 17.9622 0.121079 17.9622C0.118945 17.9622 0.117344 17.9631 0.116277 17.9647C0.11521 17.9665 0.114676 17.9688 0.114676 17.9718V17.9875H0.104004V17.9556H0.114676V17.9602C0.11601 17.9581 0.117477 17.9569 0.118945 17.9561C0.120679 17.9551 0.12268 17.9546 0.124815 17.9546C0.127216 17.9546 0.12935 17.9551 0.131218 17.9561C0.132952 17.9571 0.134419 17.9586 0.135487 17.9607L0.135486 17.9607Z"
      fill="#101820"
    />
    <path
      d="M0.201555 17.9463V17.9554H0.21276V17.9625H0.201555V17.9761C0.201555 17.9775 0.201688 17.9786 0.202088 17.9792C0.202755 17.9796 0.204223 17.9797 0.206357 17.9797H0.211693V17.9873H0.202622C0.198353 17.9873 0.195285 17.9865 0.193551 17.9848C0.191683 17.9831 0.190883 17.9802 0.190883 17.9761V17.9625H0.185547V17.9554H0.190883V17.9463L0.201555 17.9463Z"
      fill="#101820"
    />
    <path
      d="M0.253389 17.9677V17.9874H0.242183V17.9727C0.242183 17.9697 0.241917 17.9677 0.24165 17.9667C0.24165 17.9656 0.241383 17.965 0.241116 17.9646C0.240716 17.964 0.240182 17.9635 0.239515 17.9631C0.238715 17.9625 0.238048 17.9621 0.237381 17.9621C0.234846 17.9621 0.232845 17.963 0.231511 17.9646C0.230444 17.9664 0.22991 17.9687 0.22991 17.9717V17.9874H0.219238V17.9429H0.22991V17.9601C0.231645 17.9581 0.233379 17.9568 0.235246 17.956C0.236981 17.955 0.238982 17.9545 0.241116 17.9545C0.244985 17.9545 0.248053 17.9558 0.250187 17.9581C0.252322 17.9605 0.253389 17.9636 0.253389 17.9677V17.9677Z"
      fill="#101820"
    />
    <path
      d="M0.296784 17.9713V17.9743H0.271704C0.271704 17.9767 0.272371 17.9786 0.273839 17.9799C0.275573 17.9809 0.277841 17.9814 0.280775 17.9814C0.283177 17.9814 0.285578 17.9812 0.287712 17.9804C0.290113 17.9798 0.292648 17.9789 0.295182 17.9779V17.9855C0.292648 17.9865 0.28998 17.9872 0.287178 17.9875C0.284644 17.9881 0.282109 17.9885 0.279708 17.9885C0.273571 17.9885 0.268769 17.987 0.2653 17.9839C0.262099 17.9809 0.260498 17.9767 0.260498 17.9713C0.260498 17.9662 0.262099 17.9622 0.2653 17.9591C0.268769 17.9561 0.273438 17.9546 0.279174 17.9546C0.28451 17.9546 0.288779 17.9561 0.29198 17.9591C0.295182 17.9622 0.296783 17.9662 0.296783 17.9713L0.296784 17.9713ZM0.285578 17.9677C0.285578 17.9661 0.284778 17.9647 0.283444 17.9637C0.282377 17.9624 0.280909 17.9617 0.279175 17.9617C0.277041 17.9617 0.275173 17.9622 0.273839 17.9632C0.272772 17.9642 0.271971 17.9657 0.271705 17.9677L0.285578 17.9677Z"
      fill="#101820"
    />
    <path
      d="M0.326904 17.9443H0.339711L0.356253 17.9737V17.9443H0.366925V17.9874H0.354118L0.337576 17.958V17.9874H0.326904V17.9443Z"
      fill="#101820"
    />
    <path
      d="M0.394164 17.9622C0.39163 17.9622 0.389629 17.9631 0.388294 17.9647C0.387227 17.9661 0.386694 17.9683 0.386694 17.9713C0.386694 17.9743 0.387227 17.9767 0.388294 17.9784C0.389628 17.9801 0.391629 17.9809 0.394164 17.9809C0.396565 17.9809 0.398433 17.9801 0.3995 17.9784C0.400834 17.9767 0.401635 17.9743 0.401635 17.9713C0.401635 17.9683 0.400834 17.9661 0.3995 17.9647C0.398433 17.9631 0.396565 17.9622 0.394164 17.9622ZM0.394164 17.9546C0.399767 17.9546 0.404303 17.9561 0.407504 17.9591C0.410973 17.9622 0.41284 17.9662 0.41284 17.9713C0.41284 17.9767 0.410973 17.9809 0.407504 17.9839C0.404303 17.987 0.399767 17.9885 0.394164 17.9885C0.388428 17.9885 0.383759 17.987 0.380291 17.9839C0.377089 17.9809 0.375488 17.9767 0.375488 17.9713C0.375488 17.9662 0.377089 17.9622 0.380291 17.9591C0.383759 17.9561 0.388428 17.9546 0.394164 17.9546Z"
      fill="#101820"
    />
    <path
      d="M0.419922 17.9748V17.9556H0.430594V17.9586V17.9652V17.9702V17.9763C0.430861 17.9773 0.431261 17.9781 0.431661 17.9783C0.431928 17.9791 0.432462 17.9796 0.433262 17.9799C0.433929 17.9802 0.434863 17.9804 0.43593 17.9804C0.438064 17.9804 0.439799 17.9797 0.441266 17.9783C0.4426 17.9767 0.4434 17.9743 0.4434 17.9713V17.9556H0.454073V17.9874H0.4434V17.9829C0.441533 17.9847 0.439799 17.9859 0.438064 17.9869C0.436197 17.988 0.434329 17.9885 0.432195 17.9885C0.428192 17.9885 0.425258 17.9873 0.423123 17.9849C0.420989 17.9826 0.419922 17.9792 0.419922 17.9748V17.9748Z"
      fill="#101820"
    />
    <path
      d="M0.498506 17.9677V17.9875H0.487301V17.9723C0.487301 17.9696 0.487034 17.9677 0.486767 17.9667C0.486767 17.9657 0.4865 17.9651 0.486233 17.9647C0.485833 17.9641 0.4853 17.9636 0.484633 17.9632C0.483832 17.9626 0.483165 17.9622 0.482498 17.9622C0.479964 17.9622 0.477963 17.9631 0.476628 17.9647C0.475561 17.9665 0.475028 17.9688 0.475028 17.9718V17.9875H0.464355V17.9556H0.475028V17.9602C0.476762 17.9581 0.478496 17.9569 0.480364 17.9561C0.482098 17.9551 0.484099 17.9546 0.486233 17.9546C0.490102 17.9546 0.49317 17.9559 0.495304 17.9581C0.497439 17.9605 0.498506 17.9637 0.498506 17.9677V17.9677Z"
      fill="#101820"
    />
    <path
      d="M0.530273 17.9443H0.549483C0.555086 17.9443 0.559355 17.9457 0.56229 17.9484C0.565491 17.9508 0.567092 17.9542 0.567092 17.9585C0.567092 17.9629 0.565491 17.9665 0.56229 17.9691C0.559355 17.9715 0.555086 17.9727 0.549483 17.9727H0.541479V17.9874H0.530273V17.9443ZM0.541479 17.9524V17.9646H0.548416C0.55055 17.9646 0.552151 17.9641 0.553218 17.9631C0.554552 17.962 0.555352 17.9605 0.555352 17.9585C0.555352 17.9565 0.554552 17.955 0.553218 17.954C0.552151 17.9529 0.55055 17.9524 0.548416 17.9524L0.541479 17.9524Z"
      fill="#101820"
    />
    <path
      d="M0.600031 17.9642C0.598963 17.964 0.597896 17.9636 0.596829 17.9632C0.596029 17.9629 0.595228 17.9627 0.594161 17.9627C0.591226 17.9627 0.589092 17.9636 0.587758 17.9652C0.58629 17.967 0.585623 17.9695 0.585623 17.9728V17.9875H0.574951V17.9556H0.585623V17.9607C0.586957 17.9586 0.588558 17.9571 0.590426 17.9561C0.59216 17.9551 0.594294 17.9546 0.596829 17.9546H0.597896H0.60003L0.600031 17.9642Z"
      fill="#101820"
    />
    <path
      d="M0.621459 17.9622C0.618925 17.9622 0.616924 17.9631 0.615589 17.9647C0.614522 17.9661 0.613989 17.9683 0.613989 17.9713C0.613989 17.9743 0.614522 17.9767 0.615589 17.9784C0.616923 17.9801 0.618924 17.9809 0.621459 17.9809C0.62386 17.9809 0.625728 17.9801 0.626795 17.9784C0.628129 17.9767 0.62893 17.9743 0.62893 17.9713C0.62893 17.9683 0.628129 17.9661 0.626795 17.9647C0.625728 17.9631 0.62386 17.9622 0.621459 17.9622ZM0.621459 17.9546C0.627062 17.9546 0.631598 17.9561 0.634799 17.9591C0.638267 17.9622 0.640135 17.9662 0.640135 17.9713C0.640135 17.9767 0.638268 17.9809 0.634799 17.9839C0.631597 17.987 0.627062 17.9885 0.621459 17.9885C0.615723 17.9885 0.611054 17.987 0.607585 17.9839C0.604384 17.9809 0.602783 17.9767 0.602783 17.9713C0.602783 17.9662 0.604384 17.9622 0.607585 17.9591C0.611054 17.9561 0.615723 17.9546 0.621459 17.9546Z"
      fill="#101820"
    />
    <path
      d="M0.647851 17.9555H0.658523V17.9869C0.658523 17.9912 0.657456 17.9945 0.655322 17.9965C0.653187 17.9988 0.649986 18.0001 0.645717 18.0001H0.640381V17.9935H0.642515C0.644249 17.9935 0.64545 17.993 0.646251 17.992C0.647318 17.9912 0.647851 17.9896 0.647851 17.9869L0.647851 17.9555ZM0.647851 17.9429H0.658523V17.951H0.647851V17.9429Z"
      fill="#101820"
    />
    <path
      d="M0.702546 17.9713V17.9743H0.677466C0.677466 17.9767 0.678133 17.9786 0.6796 17.9799C0.681335 17.9809 0.683602 17.9814 0.686537 17.9814C0.688938 17.9814 0.691339 17.9812 0.693474 17.9804C0.695875 17.9798 0.69841 17.9789 0.700944 17.9779V17.9855C0.69841 17.9865 0.695742 17.9872 0.69294 17.9875C0.690405 17.9881 0.687871 17.9885 0.685469 17.9885C0.679333 17.9885 0.674531 17.987 0.671062 17.9839C0.66786 17.9809 0.66626 17.9767 0.66626 17.9713C0.66626 17.9662 0.667861 17.9622 0.671062 17.9591C0.67453 17.9561 0.679199 17.9546 0.684936 17.9546C0.690272 17.9546 0.694541 17.9561 0.697742 17.9591C0.700944 17.9622 0.702544 17.9662 0.702544 17.9713L0.702546 17.9713ZM0.69134 17.9677C0.69134 17.9661 0.69054 17.9647 0.689206 17.9637C0.688138 17.9624 0.686671 17.9617 0.684937 17.9617C0.682802 17.9617 0.680935 17.9622 0.679601 17.9632C0.678534 17.9642 0.677733 17.9657 0.677466 17.9677L0.69134 17.9677Z"
      fill="#101820"
    />
    <path
      d="M0.737889 17.9566V17.9647C0.736422 17.9637 0.734954 17.9631 0.73362 17.9627C0.732153 17.9624 0.730552 17.9622 0.728818 17.9622C0.725883 17.9622 0.723616 17.9631 0.721882 17.9647C0.720014 17.9661 0.719213 17.9683 0.719213 17.9713C0.719213 17.9743 0.720014 17.9767 0.721882 17.9784C0.723616 17.9801 0.725884 17.9809 0.728818 17.9809C0.730552 17.9809 0.732153 17.9808 0.73362 17.9804C0.734954 17.9798 0.736422 17.9791 0.737889 17.9784V17.9865C0.736022 17.9872 0.734287 17.9876 0.732553 17.988C0.730686 17.9882 0.729085 17.9885 0.727751 17.9885C0.721348 17.9885 0.716278 17.987 0.71281 17.9839C0.709608 17.9809 0.708008 17.9767 0.708008 17.9713C0.708008 17.9662 0.709609 17.9622 0.71281 17.9591C0.716278 17.9561 0.721348 17.9546 0.727751 17.9546C0.729485 17.9546 0.731086 17.9548 0.732553 17.9551C0.734287 17.9555 0.736022 17.956 0.737889 17.9566V17.9566Z"
      fill="#101820"
    />
    <path
      d="M0.759172 17.9463V17.9554H0.770377V17.9625H0.759172V17.9761C0.759172 17.9775 0.759305 17.9786 0.759705 17.9792C0.760372 17.9796 0.76184 17.9797 0.763974 17.9797H0.76931V17.9873H0.760239C0.75597 17.9873 0.752902 17.9865 0.751168 17.9848C0.749301 17.9831 0.7485 17.9802 0.7485 17.9761V17.9625H0.743164V17.9554H0.7485V17.9463L0.759172 17.9463Z"
      fill="#101820"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
