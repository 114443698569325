<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9014 7.18118C13.8772 7.1327 13.8043 7.08412 13.7801 7.05988L10.0682 5.0462C10.4321 4.17278 10.699 3.32371 10.699 2.69287C10.6989 1.21308 9.48581 0 8.0059 0C6.52599 0 5.31291 1.21308 5.31291 2.69299C5.31291 3.3238 5.55551 4.17289 5.9194 5.02196L2.20748 6.98705C2.13466 7.03553 2.08618 7.13259 2.08618 7.20541V12.2031C2.08618 12.276 2.13466 12.3729 2.20748 12.3973L7.86028 15.9636C7.9331 16.0121 8.03005 16.0121 8.10287 15.9636L13.804 12.9311C13.8768 12.8826 13.9253 12.8098 13.9253 12.7127L13.9254 7.30269C13.9254 7.27846 13.9254 7.22987 13.9012 7.1814L13.9014 7.18118ZM13.1735 7.30247L11.7179 8.10307L9.26759 6.7687C9.46171 6.38058 9.68007 5.94386 9.87407 5.50716L13.1735 7.30247ZM10.4563 8.90367L8.63675 7.90895C8.75805 7.6906 8.90358 7.44801 9.04923 7.15682L11.4753 8.49119V10.0924L10.5776 10.5776V9.09767C10.5776 9.02496 10.5291 8.95214 10.4563 8.90366L10.4563 8.90367ZM8.00605 0.485088C9.21913 0.485088 10.2137 1.4798 10.2137 2.69277C10.2137 4.05138 8.68536 6.88991 8.00605 8.07851C7.27828 6.86543 5.79837 4.12412 5.79837 2.69277C5.79837 1.4798 6.79297 0.485088 8.00605 0.485088ZM5.99237 5.50713L6.1379 5.57995C6.35626 6.0409 6.5746 6.50184 6.79296 6.91432L5.09481 5.99232L5.99237 5.50713ZM4.58529 6.28348L7.22967 7.71489C7.54508 8.24866 7.76344 8.63678 7.78768 8.68528C7.83616 8.7581 7.90898 8.80657 7.98181 8.80657C8.05463 8.80657 8.15157 8.7581 8.17592 8.68528C8.20016 8.66104 8.27298 8.53975 8.37004 8.36987L9.80146 9.14622L7.95768 10.1409L2.7901 7.22962L4.58529 6.28348ZM2.57161 12.0575V7.61791L7.73919 10.5535V15.3328L2.57161 12.0575ZM8.22441 15.3568V10.5532L10.0924 9.53428V11.0142C10.0924 11.1112 10.1409 11.184 10.2137 11.2325C10.2866 11.281 10.3835 11.281 10.4563 11.2325L11.8392 10.4804C11.912 10.4319 11.9605 10.3591 11.9605 10.2621V8.51532L13.4404 7.71472V12.5912L8.22441 15.3568Z"
      fill="#57697B"
    />
    <path
      d="M6.9387 11.5722L3.61507 9.63138C3.49378 9.55855 3.34825 9.60714 3.27542 9.72844C3.2026 9.84973 3.25118 9.99526 3.37248 10.0681L6.69611 12.0089C6.8174 12.0818 6.96294 12.0332 7.03576 11.9119C7.10847 11.7906 7.06 11.6451 6.9387 11.5722Z"
      fill="#57697B"
    />
    <path
      d="M6.91453 12.8095L3.5909 10.8687C3.46961 10.7959 3.32408 10.8444 3.25125 10.9657C3.17843 11.087 3.22701 11.2326 3.34831 11.3054L6.67194 13.2462C6.79323 13.3191 6.93876 13.2705 7.01159 13.1492C7.08441 13.0279 7.03594 12.8824 6.91453 12.8095Z"
      fill="#57697B"
    />
    <path
      d="M8.00603 4.12436C8.83087 4.12436 9.48594 3.44506 9.48594 2.64446C9.48594 1.81961 8.80663 1.16455 8.00603 1.16455C7.18118 1.16455 6.52612 1.84386 6.52612 2.64446C6.52612 3.4693 7.18118 4.12436 8.00603 4.12436ZM8.00603 1.6255C8.56405 1.6255 9.00074 2.08644 9.00074 2.62021C9.00074 3.17823 8.5398 3.61493 8.00603 3.61493C7.44801 3.61493 7.01131 3.15398 7.01131 2.62021C7.01131 2.08644 7.44801 1.6255 8.00603 1.6255Z"
      fill="#57697B"
    />
    <path
      d="M2.18344 5.48287C2.18344 5.57993 2.23192 5.65264 2.30474 5.70122C2.37756 5.7497 2.47451 5.72546 2.54733 5.67699L4.73077 4.07579C4.80359 4.02732 4.82782 3.95449 4.82782 3.88167C4.82782 3.80885 4.77935 3.73614 4.73077 3.68755L2.54733 2.08635C2.47451 2.03788 2.37756 2.01353 2.30474 2.06212C2.23203 2.13494 2.18344 2.20765 2.18344 2.30471V2.81412L0.242593 2.81423C0.0970613 2.81423 0 2.91129 0 3.05682V4.75509C0 4.90062 0.0970585 4.99769 0.242593 4.99769H2.18344V5.48287ZM0.485174 4.48815V3.27507H2.42603C2.57156 3.27507 2.66862 3.17802 2.66862 3.03248V2.76565L4.19699 3.88166L2.66862 5.02191V4.75508C2.66862 4.60955 2.57156 4.51249 2.42603 4.51249L0.485174 4.51238V4.48815Z"
      fill="#57697B"
    />
    <path
      d="M15.7696 2.81436H13.8287V2.30484C13.8287 2.20778 13.7802 2.13507 13.7074 2.08648C13.6346 2.03801 13.5376 2.06224 13.4648 2.11072L11.2814 3.71192C11.2086 3.76039 11.1843 3.83321 11.1843 3.90603C11.1843 3.97886 11.2328 4.05157 11.2814 4.10015L13.4648 5.70135C13.5376 5.74982 13.6346 5.77417 13.7074 5.72559C13.7802 5.67711 13.8287 5.60429 13.8287 5.50723V4.99782H15.7696C15.9151 4.99782 16.0122 4.90077 16.0122 4.75523V3.05696C16.0122 2.91132 15.9151 2.81437 15.7696 2.81437V2.81436ZM15.527 4.48827H13.5861C13.4406 4.48827 13.3435 4.58533 13.3435 4.73086V4.99769L11.8151 3.88168L13.3435 2.76568V3.03251C13.3435 3.17804 13.4406 3.2751 13.5861 3.2751H15.527V4.48827Z"
      fill="#57697B"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
