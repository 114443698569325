<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
  >
    <g filter="url(#filter0_d_1821_7840)">
      <circle cx="25" cy="23" r="15" fill="white" />
    </g>
    <g clip-path="url(#clip0_1821_7840)">
      <path
        d="M29.5 20C29.5 21.1935 29.0259 22.3381 28.182 23.182C27.3381 24.0259 26.1935 24.5 25 24.5C23.8065 24.5 22.6619 24.0259 21.818 23.182C20.9741 22.3381 20.5 21.1935 20.5 20C20.5 18.8065 20.9741 17.6619 21.818 16.818C22.6619 15.9741 23.8065 15.5 25 15.5C26.1935 15.5 27.3381 15.9741 28.182 16.818C29.0259 17.6619 29.5 18.8065 29.5 20Z"
        fill="#E33065"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 23C13 19.8174 14.2643 16.7652 16.5147 14.5147C18.7652 12.2643 21.8174 11 25 11C28.1826 11 31.2348 12.2643 33.4853 14.5147C35.7357 16.7652 37 19.8174 37 23C37 26.1826 35.7357 29.2348 33.4853 31.4853C31.2348 33.7357 28.1826 35 25 35C21.8174 35 18.7652 33.7357 16.5147 31.4853C14.2643 29.2348 13 26.1826 13 23ZM25 12.5C23.0227 12.5001 21.0855 13.0585 19.4116 14.111C17.7377 15.1635 16.3949 16.6673 15.5379 18.4493C14.681 20.2313 14.3446 22.219 14.5675 24.1837C14.7904 26.1485 15.5636 28.0103 16.798 29.555C17.863 27.839 20.2075 26 25 26C29.7925 26 32.1355 27.8375 33.202 29.555C34.4364 28.0103 35.2096 26.1485 35.4325 24.1837C35.6554 22.219 35.319 20.2313 34.4621 18.4493C33.6051 16.6673 32.2623 15.1635 30.5884 14.111C28.9145 13.0585 26.9773 12.5001 25 12.5Z"
        fill="#E33065"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1821_7840"
        x="0"
        y="0"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.341176 0 0 0 0 0.411765 0 0 0 0 0.482353 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1821_7840"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1821_7840"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_1821_7840">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(13 11)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
