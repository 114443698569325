import * as types from "../types";
import { adminApi } from "@/api";
import { ActionContext } from "vuex";
import router from "@/router";
import { handleLocalStorage, clearLocalStorage } from "@/utils";
import { IAuthState } from "./module.interface";

const state: IAuthState = {
  user: JSON.parse(localStorage.getItem("sm-user") || "{}"),
};
const getters = {
  authenticated({ user }: IAuthState) {
    return user === null ? null : Object.keys(user)?.length > 0;
  },
  token({ user }: IAuthState) {
    if (user) {
      return user.bearer;
    }

    return null;
  },
  [types.GET_USER]({ user }: IAuthState) {
    return user;
  },
};
const mutations = {
  [types.SET_USER](state: IAuthState, value: any) {
    state.user = value;
    value
      ? localStorage.setItem("sm-user", JSON.stringify(value))
      : localStorage.removeItem("sm-user");
  },
};
const actions = {
  // eslint-disable-next-line no-unused-vars
  register(
    { commit, dispatch, getters }: ActionContext<IAuthState, any>,
    data: any
  ) {
    if (getters.authenticated) return Promise.resolve(null);
    // return WestoreAuth.Register(data).then(({ data }) => {
    //   const user = data.data;
    //   commit("SET_USER", user);

    //   return user;
    // });
  },
  // eslint-disable-next-line no-unused-vars
  [types.LOGIN_USER]({ commit }: ActionContext<IAuthState, any>, data: any) {
    commit(types.SET_USER, data);

    // return WestoreAuth.Login(credentials).then(({ data }) => {
    //   const user = data.data;
    //   commit("SET_USER", user);
    //   commit("SET_META", data.meta);
    //   return user;
    // });
  },
  [types.LOGOUT_USER]({ commit, getters }: ActionContext<IAuthState, any>) {
    commit(types.SET_USER, null);
    router.replace({ name: "login" });
  },
  // eslint-disable-next-line no-unused-vars
  forgotPassword() {
    // return WestoreAuth.ForgotPassword(email);
  },
  // eslint-disable-next-line no-unused-vars
  resetPassword() {
    // return WestoreAuth.ResetPassword(token, payload);
  },
  // eslint-disable-next-line no-unused-vars
  validate({
    commit,
    dispatch,
    getters,
    state,
  }: ActionContext<IAuthState, any>) {
    // return WestoreAuth.WithCredentials(getters.bearer_token)
    //   .Validate()
    //   .then(() => {
    //     if (!state.user) {
    //       WestoreAuth.User().then((user) => {
    //         commit("SET_USER", user);
    //       });
    //     }
    //     return state.user.token;
    //   })
    //   .catch((err) => {
    //     if ("response" in err && err.response.status === 401) {
    //       dispatch("terminate");
    //     }
    //   });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
