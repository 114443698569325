<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1817_7524)">
      <path
        d="M15.75 1.125C16.0484 1.125 16.3345 1.24353 16.5455 1.4545C16.7565 1.66548 16.875 1.95163 16.875 2.25V11.25C16.875 11.5484 16.7565 11.8345 16.5455 12.0455C16.3345 12.2565 16.0484 12.375 15.75 12.375H12.9375C12.5882 12.375 12.2437 12.4563 11.9313 12.6125C11.6188 12.7688 11.3471 12.9956 11.1375 13.275L9 16.1246L6.8625 13.275C6.65292 12.9956 6.38116 12.7688 6.06873 12.6125C5.75631 12.4563 5.4118 12.375 5.0625 12.375H2.25C1.95163 12.375 1.66548 12.2565 1.4545 12.0455C1.24353 11.8345 1.125 11.5484 1.125 11.25V2.25C1.125 1.95163 1.24353 1.66548 1.4545 1.4545C1.66548 1.24353 1.95163 1.125 2.25 1.125H15.75ZM2.25 0C1.65326 0 1.08097 0.237053 0.65901 0.65901C0.237053 1.08097 0 1.65326 0 2.25L0 11.25C0 11.8467 0.237053 12.419 0.65901 12.841C1.08097 13.2629 1.65326 13.5 2.25 13.5H5.0625C5.23715 13.5 5.4094 13.5407 5.56562 13.6188C5.72183 13.6969 5.85771 13.8103 5.9625 13.95L8.1 16.7996C8.20479 16.9393 8.34067 17.0528 8.49688 17.1309C8.6531 17.209 8.82535 17.2496 9 17.2496C9.17465 17.2496 9.3469 17.209 9.50311 17.1309C9.65933 17.0528 9.79521 16.9393 9.9 16.7996L12.0375 13.95C12.1423 13.8103 12.2782 13.6969 12.4344 13.6188C12.5906 13.5407 12.7628 13.5 12.9375 13.5H15.75C16.3467 13.5 16.919 13.2629 17.341 12.841C17.7629 12.419 18 11.8467 18 11.25V2.25C18 1.65326 17.7629 1.08097 17.341 0.65901C16.919 0.237053 16.3467 0 15.75 0L2.25 0Z"
        fill="#CB3243"
      />
      <path
        d="M5.625 6.75C5.625 7.04837 5.50647 7.33452 5.29549 7.54549C5.08452 7.75647 4.79837 7.875 4.5 7.875C4.20163 7.875 3.91548 7.75647 3.7045 7.54549C3.49353 7.33452 3.375 7.04837 3.375 6.75C3.375 6.45163 3.49353 6.16548 3.7045 5.95451C3.91548 5.74353 4.20163 5.625 4.5 5.625C4.79837 5.625 5.08452 5.74353 5.29549 5.95451C5.50647 6.16548 5.625 6.45163 5.625 6.75ZM10.125 6.75C10.125 7.04837 10.0065 7.33452 9.7955 7.54549C9.58452 7.75647 9.29837 7.875 9 7.875C8.70163 7.875 8.41548 7.75647 8.2045 7.54549C7.99353 7.33452 7.875 7.04837 7.875 6.75C7.875 6.45163 7.99353 6.16548 8.2045 5.95451C8.41548 5.74353 8.70163 5.625 9 5.625C9.29837 5.625 9.58452 5.74353 9.7955 5.95451C10.0065 6.16548 10.125 6.45163 10.125 6.75ZM14.625 6.75C14.625 7.04837 14.5065 7.33452 14.2955 7.54549C14.0845 7.75647 13.7984 7.875 13.5 7.875C13.2016 7.875 12.9155 7.75647 12.7045 7.54549C12.4935 7.33452 12.375 7.04837 12.375 6.75C12.375 6.45163 12.4935 6.16548 12.7045 5.95451C12.9155 5.74353 13.2016 5.625 13.5 5.625C13.7984 5.625 14.0845 5.74353 14.2955 5.95451C14.5065 6.16548 14.625 6.45163 14.625 6.75Z"
        fill="#CB3243"
      />
    </g>
    <defs>
      <clipPath id="clip0_1817_7524">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
