import { RouteConfig, Route, NavigationGuardNext } from "vue-router";
import { store } from "@/state/store";
import * as types from "@/state/types";
export const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/warehousing/order-requests",
    name: "home",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "Home-page" */ "@/router/pages/Home/Home.vue"
      ),
  },
  {
    path: "/warehousing/order-requests",

    name: "order-requests",
    children: [
      {
        path: "",
        name: "recent-orders",
        component: () =>
          import(
            /* webpackChunkName: "Home-page" */ "@/router/pages/OrderRequests/RecentOrders/RecentOrders.vue"
          ),
      },
      {
        path: "all-orders",
        name: "all-orders",
        component: () =>
          import(
            /* webpackChunkName: "Home-page" */ "@/router/pages/OrderRequests/AllOrders/AllOrders.vue"
          ),
      },
    ],
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "Home-page" */ "@/router/pages/OrderRequests/OrderRequests.vue"
      ),
  },
  {
    path: "/warehousing/order-requests/:id",
    name: "show-order",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "Home-page" */ "@/router/pages/ShowOrder/ShowOrder.vue"
      ),
  },
  {
    path: "*",
    name: "404",

    component: () =>
      import(/* webpackChunkName: "Home-page" */ "@/router/pages/notFound.vue"),
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: () =>
      import(
        /* webpackChunkName: "Home-page" */ "@/router/pages/Auth/ResetPassword.vue"
      ),
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () =>
      import(
        /* webpackChunkName: "Home-page" */ "@/router/pages/Auth/ForgetPassword.vue"
      ),
  },
  {
    path: "/email-sent/:email",
    name: "email-sent",
    component: () =>
      import(
        /* webpackChunkName: "Home-page" */ "@/router/pages/Auth/EmailSent.vue"
      ),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(
        /* webpackChunkName: "Home-page" */ "@/router/pages/Auth/Login.vue"
      ),
  },
];
