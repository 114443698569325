<script>
export default {
  props: ["selected", "largeSize", "disabled"],

  data() {
    return {};
  },

  watch: {},

  methods: {},
};
</script>

<template>
  <div
    class="checkbox-item border bg-white rounded cursor-pointer w-5 h-5"
    :class="{
      'border-primary': selected && !disabled,
      'border-gray-8': !selected || disabled,
      'hover:border-primary': !disabled,
      'cursor-not-allowed opacity-50': disabled,
    }"
  >
    <svg
      v-if="selected"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.16675 0.666504H14.8334C16.2141 0.666504 17.3334 1.78579 17.3334 3.1665V14.8332C17.3334 16.2139 16.2141 17.3332 14.8334 17.3332H3.16675C1.78604 17.3332 0.666748 16.2139 0.666748 14.8332V3.1665C0.666748 1.78579 1.78604 0.666504 3.16675 0.666504ZM7.54175 12.8582L13.7584 6.2415C14.0405 5.91451 14.026 5.42622 13.7251 5.1165C13.5686 4.95874 13.3556 4.87001 13.1334 4.87001C12.9112 4.87001 12.6982 4.95874 12.5417 5.1165L6.93341 11.0832L5.46675 9.52484C5.26929 9.28324 4.95341 9.1722 4.6482 9.23709C4.343 9.30198 4.09962 9.53193 4.01753 9.83296C3.93544 10.134 4.0284 10.4557 4.25841 10.6665L6.32508 12.8582C6.48358 13.0244 6.7037 13.1179 6.93341 13.1165C7.16313 13.1179 7.38324 13.0244 7.54175 12.8582Z"
        fill="#101820"
      />
    </svg>
  </div>
</template>

<style>
.checkbox-item {
  transition: all 0.1s ease-in-out;
}
</style>
