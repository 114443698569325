var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('check-box',{staticClass:"mr-4",attrs:{"selected":_vm.allItemsSelected},nativeOn:{"click":function($event){return _vm.toggleAllItemsSelection.apply(null, arguments)}}}),_c('span',{staticClass:"font-heading font-medium text-sm text-gray-8"},[_vm._v("Select All ")])],1),_c('div',{staticClass:"font-heading font-medium text-sm text-gray-8"},[_vm._v(" Selected: "),_c('span',{staticClass:"text-info"},[_vm._v("( "+_vm._s(_vm.selectedItemsIds.length)+" )")]),_vm._v(" Orders ")])]),_c('div',{staticClass:"pb-10 mt-10"},_vm._l((_vm.orders),function(order){return _c('table-row',{key:order.id,attrs:{"order":order,"toggleItemSelection":_vm.toggleItemSelection,"itemIsSelected":_vm.itemIsSelected}})}),1),_c('div',{staticClass:"flex justify-end"},[_c('div',{staticClass:"flex align-start justify-end"},[_c('div',{staticClass:"text-center",class:_vm.meta.current_page === 1 ? ' cursor-not-allowed' : 'cursor-pointer',on:{"click":function($event){return _vm.$emit('paginate', 1)}}},[_c('v-btn',{attrs:{"disabled":_vm.meta.current_page === 1,"icon":""}},[_c('v-icon',[_vm._v("$vuetify.icons.paginationFirst")])],1),_c('div',{staticClass:"font-standard text-xs text-info"},[_vm._v("First")])],1),_c('div',_vm._g({staticClass:"bg-info rounded-full text-center cursor-pointer w-6 h-6 mt-2 mx-2",class:_vm.meta.current_page === 1 ? 'bg-gray-8 cursor-not-allowed' : ''},
          _vm.meta.current_page !== 1
            ? { click: () => _vm.$emit('paginate', _vm.meta.current_page - 1) }
            : {}
        ),[_c('v-icon',{staticClass:"mb-0.5",attrs:{"size":"16"}},[_vm._v("$vuetify.icons.paginationPrev")])],1),_vm._l((_vm.meta.last_page),function(n){return _c('div',{key:n,staticClass:"bg-gray-6 rounded-full text-center cursor-pointer w-6 h-6 mt-2 mx-2",class:_vm.meta.current_page === n ? 'bg-primary text-white' : 'text-gray',on:{"click":function($event){return _vm.$emit('paginate', n)}}},[_vm._v(" "+_vm._s(n)+" ")])}),_c('div',_vm._g({staticClass:"bg-info rounded-full text-center cursor-pointer w-6 h-6 mt-2 mx-2",class:_vm.meta.current_page === _vm.meta.last_page
            ? 'bg-gray-8 cursor-not-allowed'
            : ''},
          _vm.meta.current_page !== _vm.meta.last_page
            ? { click: () => _vm.$emit('paginate', _vm.meta.current_page + 1) }
            : {}
        ),[_c('v-icon',{staticClass:"mb-0.5",attrs:{"size":"16"}},[_vm._v("$vuetify.icons.paginationNext")])],1),_c('div',_vm._g({staticClass:"text-center",class:_vm.meta.current_page === _vm.meta.last_page
            ? ' cursor-not-allowed'
            : 'cursor-pointer'},
          _vm.meta.current_page !== _vm.meta.last_page
            ? { click: () => _vm.$emit('paginate', _vm.meta.last_page) }
            : {}
        ),[_c('v-btn',{attrs:{"disabled":_vm.meta.current_page === _vm.meta.last_page,"icon":""}},[_c('v-icon',[_vm._v("$vuetify.icons.paginationLast")])],1),_c('div',{staticClass:"font-standard text-xs text-info"},[_vm._v("Last")])],1)],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }